import { createAction, props } from '@ngrx/store';
import { Parking, ParkingFilters, SelectedFilters } from './parkings.models';

import {
  CommentInternal,
  QuotaComment
} from './details/parking.details.models';
import {
  PaginatedListResponse,
  DetailsItem
} from '../../../../core/models/http-response.models';

export const loadParkings = createAction(
  '[Parkings] Load data',
  props<{
    page: number;
    filters: SelectedFilters;
    q: string;
    sortBy: string;
    sortOrder: string;
  }>()
);

export const parkingsLoadSucess = createAction(
  '[Parkings] Load data success',
  props<{ result: PaginatedListResponse<Parking> }>()
);

export const parkingsLoadFailure = createAction(
  '[Parkings] Load data failure',
  props<{ error: any }>()
);

export const loadFilters = createAction('[Parkings] Load Filters');

export const loadFiltersSucess = createAction(
  '[Parkings] Load Filters success',
  props<{ result: ParkingFilters }>()
);

export const loadFiltersFailure = createAction(
  '[Parkings] Load Filters failure',
  props<{ error: any }>()
);

// CommentInternal load
export const loadCommentInternal = createAction(
  '[CommentInternal] Load data',
  props<{ sortBy: string; sortOrder: string }>()
);

export const CommentInternalLoadSucess = createAction(
  '[CommentInternal] Load data success',
  props<{ result: PaginatedListResponse<CommentInternal> }>()
);

export const CommentInternalLoadFailure = createAction(
  '[CommentInternal] Load data failure',
  props<{ error: any }>()
);

// CommentInternal detail
// export const loadCommentInternalDetail = createAction(
//   '[CommentInternal] Load Detail data',
//   props<{ parkingId: string }>()
// );

// export const CommentInternalLoadDetailSucess = createAction(
//   '[CommentInternal] Load Detail data success',
//   props<{ result: DetailsItem<CommentInternal> }>()
// );

// export const CommentInternalLoadDetailFailure = createAction(
//   '[CommentInternal] Load Detail data failure',
//   props<{ error: any }>()
// );

// CommentInternal creation
export const createCommentInternal = createAction(
  '[CommentInternal] Create CommentInternal',
  props<{ parkingId: string; data: CommentInternal }>()
);

export const createCommentInternalSucess = createAction(
  '[CommentInternal] Create CommentInternal Success',
  props<{ result: DetailsItem<CommentInternal> }>()
);

export const createCommentInternalFailure = createAction(
  '[CommentInternal] Create CommentInternal Failure',
  props<{ error: any }>()
);

// quotaComment
export const createQuotaComment = createAction(
  '[QuotaComment] Create QuotaComment',
  props<{ parkingId: string; data: QuotaComment }>()
);

export const createQuotaCommentSucess = createAction(
  '[QuotaComment] Create QuotaComment Success',
  props<{ result: DetailsItem<QuotaComment> }>()
);

export const createQuotaCommentFailure = createAction(
  '[QuotaComment] Create QuotaComment Failure',
  props<{ error: any }>()
);
