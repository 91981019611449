import { Action, createReducer, on } from '@ngrx/store';
import { BookingsState } from './bookings.models';
import { BookingsLoadFailure, BookingsLoadSucess, loadBookings, loadBookingFilters, loadBookingsFiltersSucess, loadBookingFiltersFailure, loadBookingDetail, bookingLoadDetailSucess, bookingLoadDetailFailure, loadGates, loadGatesSucess, loadGatesFailure, cancelExcess, cancelExcessSucess, cancelExcessFailure } from './bookings.actions';

export const initialState: BookingsState = {
    bookings: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        },
        filters: {
            loading: false,
            error: null,
            listFilter: {
                states: null,
                excess: null,
            }
        }
    },
    detail: {
        loading: false,
        error: null,
        list: {
            data: null,
        }
    },
    gates: {
        loading: false,
        error: null,
        list: null
    }

};
const reducer = createReducer(
    initialState,
    on(BookingsLoadSucess, (state, action) => ({
        ...state,
        ...{
            bookings: {
                loading: false,
                error: null,
                list: action.result,
                filters: initialState.bookings.filters
            }
        }
    })),
    on(BookingsLoadFailure, (state, action) => ({
        ...state,
        ...{
            bookings: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.bookings.filters
            }
        }
    })),
    on(loadBookings, (state, action) => ({
        ...state,
        ...{
            bookings: {
                loading: true,
                error: null,
                list: initialState.bookings.list,
                filters: initialState.bookings.filters
            }
        }
    })),
    on(loadBookingFilters, (state, action) => ({
        ...state,
        ...{
            bookings: {
                loading: true,
                error: null,
                list: initialState.bookings.list,
                filters: {
                    loading: true,
                    error: null,
                    listFilter: state.bookings.filters.listFilter
                }
            }
        }
    })),

    on(loadBookingsFiltersSucess, (state, action) => ({
        ...state,
        ...{
            bookings: {
                loading: false,
                error: null,
                list: initialState.bookings.list,
                filters: {
                    loading: false,
                    error: null,
                    listFilter: action.result
                }
            }
        }
    })),

    on(loadBookingFiltersFailure, (state, action) => ({
        ...state,
        ...{
            bookings: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.bookings.filters
            }
        }
    })),

    on(loadGates, (state, action) => ({
        ...state,
        ...{
            gates: {
                loading: true,
                error: null,
                list: initialState.gates.list
            }
        }
    })),

    on(loadGatesSucess, (state, action) => ({
        ...state,
        ...{
            gates: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),
    on(loadGatesFailure, (state, action) => ({
        ...state,
        ...{
            gates: {
                loading: false,
                error: action.error,
                list: initialState.gates.list
            }
        }
    })),

    on(loadBookingDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(bookingLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(bookingLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),

    on(cancelExcess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: state.detail.list
            }
        }
    })),

    on(cancelExcessSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: state.detail.list
            }
        }
    })),

    on(cancelExcessFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: state.detail.list
            }
        }
    })),


)
export function bookingsReducer(
    state: BookingsState | undefined,
    action: Action
): BookingsState {
    return reducer(state, action);
}