import { map } from 'rxjs/operators';
import { Action, createReducer, on } from '@ngrx/store';
import { LivesState, GateOpening } from './lives.models';
import { LivesLoadFailure, LivesLoadSucess, loadLives, loadLiveDetail, liveLoadDetailSucess, liveLoadDetailFailure, LivesGatesLoadSucess, LivesGatesLoadFailure, loadLivesGates, flushLiveDetail } from './lives.actions';

export const initialState: LivesState = {
    lives: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        },

    },
    opennings: {
        loading: false,
        error: null,
        map: null
    },
    gates: {
        loading: false,
        error: null,
        list: null
    }


};
const reducer = createReducer(
    initialState,
    on(LivesLoadSucess, (state, action) => ({
        ...state,
        ...{
            lives: {
                loading: false,
                error: null,
                list: action.result,
            }
        }
    })),
    on(LivesLoadFailure, (state, action) => ({
        ...state,
        ...{
            lives: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
            }
        }
    })),
    on(loadLives, (state, action) => ({
        ...state,
        ...{
            lives: {
                loading: true,
                error: null,
                list: initialState.lives.list,
            }
        }
    })),

    on(LivesGatesLoadSucess, (state, action) => ({
        ...state,
        ...{
            gates: {
                loading: false,
                error: null,
                list: action.result,
            }
        }
    })),
    on(LivesGatesLoadFailure, (state, action) => ({
        ...state,
        ...{
            gates: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
            }
        }
    })),
    on(loadLivesGates, (state, action) => ({
        ...state,
        ...{
            gates: {
                loading: true,
                error: null,
                list: initialState.gates.list,
            }
        }
    })),

    on(loadLiveDetail, (state, action) => ({
        ...state,
        ...{
            opennings: {
                loading: true,
                error: null,
                map: state.opennings.map
            }
        }
    })),

    on(flushLiveDetail, (state, action) => ({
        ...state,
        ...{
            opennings: {
                loading: false,
                error: null,
                map: initialState.opennings.map
            }
        }
    })),

    

    on(liveLoadDetailSucess, (state, action) => {
        let newState: Map<string, GateOpening[]> = state.opennings.map ;
        if (!(newState instanceof Map)) {
            newState = new Map<string, GateOpening[]>();
        }
        if (!!action.result && !!action.result.data) {
            newState.set(action.id, action.result.data)
        } else {
            newState.set(action.id, [])
        }
        return {
            ...state,
            ...{
                opennings: {
                    loading: false,
                    error: null,
                    map: newState
                }
            }
        }
    }),

    on(liveLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            opennings: {
                loading: false,
                error: action.error,
                map: state.opennings.map
            }
        }
    })),

)
export function livesReducer(
    state: LivesState | undefined,
    action: Action
): LivesState {
    return reducer(state, action);
}