import { DetailsItem } from './../../../../core/models/http-response.models';
import { Customer } from './customers.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse } from '../../../../core/models/http-response.models';

export const loadCustomers = createAction('[Customers] Load data', props<{ page: number, q: string, sortBy: string, sortOrder: string }>());
export const customersLoadSucess = createAction('[Customers] Load data success', props<{ result: PaginatedListResponse<Customer> }>());
export const customersLoadFailure = createAction('[Customers] Load data failure', props<{ error: any }>());

export const loadCustomerDetail = createAction('[Customers] Load Detail data', props<{ id: string }>());
export const customersLoadDetailSucess = createAction('[Customers] Load Detail data success', props<{ result: DetailsItem<Customer> }>());
export const customersLoadDetailFailure = createAction('[Customers] Load Detail data failure', props<{ error: any }>());

export const deleteCustomer = createAction('[Customers] delete customer data', props<{ id: string }>());
export const customerDeleteSucess = createAction('[Customers] delete customer data success', props<{ result: any }>());
export const customerDeleteFailure = createAction('[Customers] delete customer data failure', props<{ error: any }>());

export const pushCustomerData = createAction('[Customers] push customer data', props<{ data: Customer }>());
export const customerPushSucess = createAction('[Customers] push customer data success', props<{ result: any }>());
export const customerPushFailure = createAction('[Customers] push customer data failure', props<{ error: any }>());


export const resetPassword = createAction('[Customers] reset password data', props<{ id: string }>());
export const resetPasswordSucess = createAction('[Customers] reset password data success', props<{ result: any }>());
export const resetPasswordFailure = createAction('[Customers] reset password data failure', props<{ error: any }>());
