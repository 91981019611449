import { environment } from './../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import browser from 'browser-detect';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { environment as env } from '../../environments/environment';

import {
  authLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated
} from '../core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '../core/settings/settings.actions';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { UserInfo } from '../core/auth/auth.model';

@Component({
  selector: 'lpa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  languages = ['en', 'fr'];
  isAuthenticated$: Observable<boolean>;
  // language$: Observable<string>;
  // theme$: Observable<string>;
  authUser$: Observable<UserInfo>;
  private currentEnv: string;

  @ViewChild('envSelect', { static: false }) envSelect: MatSelect;

  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService,
    private httpClient: HttpClient
  ) {}

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    const id = uuid();
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.keys.google.apiKey}&libraries=places&language=en`,
        'callback'
      )
      .pipe(
        map(() => true),
        catchError(e => {
          console.log(e);
          return of(false);
        })
      )
      .subscribe();

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    // TODO Remove this line
    this.isAuthenticated$ = of(true);
    // this.language$ = this.store.pipe(select(selectSettingsLanguage));
    // this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    // this.appGlobals$ = this.store.pipe(select(selectAppState));
  }

  onLogoutClick() {
    this.store.dispatch(authLogout());
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }
}
