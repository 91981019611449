import { Parking } from './../../parkings/core/parkings.models';
import { DetailsItem } from './../../../../core/models/http-response.models';
import { HourlyRate, HourlyRatePrice, SimulateResponse } from './hourly-rates.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse } from '../../../../core/models/http-response.models';

export const loadHourlyRates = createAction('[Hourly Rates] Load data', props<{ page: number, q: string, sortBy: string, sortOrder: string }>());
export const hourlyRatesLoadSucess = createAction('[Hourly Rates] Load data success', props<{ result: PaginatedListResponse<HourlyRate> }>());
export const hourlyRatesLoadFailure = createAction('[Hourly Rates] Load data failure', props<{ error: any }>());


export const deleteHourlyRates = createAction('[Hourly Rates] Delete data', props<{ id: number, sortBy: string, sortOrder: string}>());
export const hourlyRatesDeleteSucess = createAction('[Hourly Rates] Delete data success', props<{ result: any }>());
export const hourlyRatesDeleteFailure = createAction('[Hourly Rates] Delete data failure', props<{ error: any }>());

export const loadHourlyRate = createAction('[Hourly Rates] Load detail data', props<{ id: number }>());
export const hourlyRateLoadSucess = createAction('[Hourly Rates] Load detail data success', props<{ result: DetailsItem<HourlyRate> }>());
export const hourlyRateLoadFailure = createAction('[Hourly Rates] Load detail data failure', props<{ error: any }>());

export const pushHourlyRate = createAction('[Hourly Rates] Push data', props<{ data: HourlyRate }>());
export const hourlyRatePushSucess = createAction('[Hourly Rates] Push data success', props<{ result: HourlyRate }>());
export const hourlyRatePushFailure = createAction('[Hourly Rates] Push data failure', props<{ error: any }>());

export const loadHourlyRatePrices = createAction('[Hourly Rates Prices] Load data', props<{ rateId: string, page: number, q: string, sortBy: string, sortOrder: string }>());
export const hourlyRatePricesLoadSucess = createAction('[Hourly Rates Prices] Load data success', props<{ result: PaginatedListResponse<HourlyRatePrice> }>());
export const hourlyRatePricesLoadFailure = createAction('[Hourly Rates Prices] Load data failure', props<{ error: any }>());

export const loadHourlyRateParkings = createAction('[Hourly Rates Parkings] Load data', props<{ rateId: string, page: number, q: string, sortBy: string, sortOrder: string }>());
export const hourlyRateParkingsLoadSucess = createAction('[Hourly Rates Parkings] Load data success', props<{ result: PaginatedListResponse<Parking> }>());
export const hourlyRateParkingsLoadFailure = createAction('[Hourly Rates Parkings] Load data failure', props<{ error: any }>());

export const loadHourlyRateSimulation = createAction('[Hourly Rates Simulation] Load data', props<{ rateId: string, startDate: string, endDate: string }>());
export const hourlyRateSimulationLoadSucess = createAction('[Hourly Rates Simulation] Load data success', props<{ result: SimulateResponse }>());
export const hourlyRateSimulationLoadFailure = createAction('[Hourly Rates Simulation] Load data failure', props<{ error: any }>());


export const pushHourlyRatePrices = createAction('[Hourly Rates Prices] Push data', props<{ d: HourlyRatePrice, rateId: string }>());
export const syncHourlyRatePrices = createAction('[Hourly Rates Prices] Sync data', props<{ d: any, rateId: string }>());
export const hourlyRatePricesPushSucess = createAction('[Hourly Rates Prices] Push data success', props<{ result: DetailsItem<HourlyRatePrice> }>());
export const hourlyRatePricesPushFailure = createAction('[Hourly Rates Prices] Push data failure', props<{ error: any }>());
