import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';
import { Vehicle, VehicleFormData, VehiclePostData } from './vehicles.models';

export const loadVehicles = createAction('[Vehicles] Load data', props<{ page: number, q: string, sortBy: string, sortOrder: string, filterType: string }>());
export const vehiclesLoadSucess = createAction('[Vehicles] Load data success', props<{ result: PaginatedListResponse<Vehicle> }>());
export const vehiclesLoadFailure = createAction('[Vehicles] Load data failure', props<{ error: any }>());

export const loadVehiclesDetail = createAction('[Vehicles Details] Load data', props<{ id: number }>());
export const vehiclesDetailLoadSucess = createAction('[Vehicles Details] Load data success', props<{ result: DetailsItem<Vehicle> }>());
export const vehiclesDetailLoadFailure = createAction('[Vehicles Details] Load data failure', props<{ error: any }>());

export const loadVehiclesFormData = createAction('[Vehicles Form Data] Load data');
export const vehiclesFormDataLoadSucess = createAction('[Vehicles Form Data] Load data success', props<{ result: VehicleFormData }>());
export const vehiclesFormDataFailure = createAction('[Vehicles Form Data] Load data failure', props<{ error: any }>());

export const pushVehiclesDetail = createAction('[Vehicles Details] Push data', props<{ data: VehiclePostData }>());
export const vehiclesDetailPushSucess = createAction('[Vehicles Details] Push data success', props<{ result: VehiclePostData }>());
export const vehiclesDetailPushFailure = createAction('[Vehicles Details] Push data failure', props<{ error: any }>());




