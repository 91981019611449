import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  LoginRequest,
  AuthToken,
  RefreshTokenRequest,
  UserInfo,
} from './auth.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { share } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpClient: HttpClient) { }

  retrieveAuthToken(
    request: LoginRequest,
  ): Observable<AuthToken> {
    const body = new HttpParams()
      .set('password', request.password)
      .set('email', request.username);

    return this.httpClient.post<AuthToken>(
      `${environment.api.BASE_URL}${environment.api.API_VERSION}${environment.api.endpoints.auth.LOGIN}`,
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  retrieveRefreshToken(tokenInfos: RefreshTokenRequest): Observable<AuthToken> {
    return this.httpClient
      .post<AuthToken>(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${environment.api.endpoints.auth.REFRESH_TOKEN}`,
        {},
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', `Bearer ${tokenInfos.refresh_token}`)
        }
      )
      .pipe(share());
  }

  retrieveUserData(token : AuthToken): Observable<UserInfo> {
    return this.httpClient
      .get<UserInfo>(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${environment.api.endpoints.auth.ME}`,
        {
          headers: new HttpHeaders()
            .set('Authorization', `Bearer ${token.access_token}`)
        }
      )
      .pipe(share());
  }

  decodeToken(token) {
    try {
      return jwtDecode(token);
    } catch (e) {
      console.log('Cannot decode token: ', e);
      return null;
    }
  }

  getUserRoles(token) {
    const data = this.decodeToken(token);
    if (!!data.role) {
      const roles = data.role.split(',');
      return roles.map(role => role.trim().toLowerCase());
    }

    return [];
  }

  hasAccessRoles(token): boolean {
    // TODO use getUserRoles to retrieve user roles 
    return true;
  }

  logout(): Observable<any> {
    return of(null);
  }
}
