import { createSelector } from '@ngrx/store';

import { selectAuthState } from '../core.state';
import { AuthState } from './auth.model';

export const selectAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
);
export const selectAuthToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const selectAuthUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.userInfo
);

export const selectAuthUserRole = createSelector(
  selectAuthState,
  (state: AuthState) => state.userInfo.user_role
);
