import {
  PaymentFilters,
  Payment,
  SelectedFilters,
  PaymentReceipt,
  PaymentRefund
} from './payments.models';
import { createAction, props } from '@ngrx/store';
import {
  PaginatedListResponse,
  DetailsItem
} from '../../../../core/models/http-response.models';

export const loadPayments = createAction(
  '[Payments] Load data',
  props<{
    page: number;
    filters: SelectedFilters;
    q: string;
    sortBy: string;
    sortOrder: string;
  }>()
);

export const PaymentsLoadSucess = createAction(
  '[Payments] Load data success',
  props<{ result: PaginatedListResponse<Payment> }>()
);

export const PaymentsLoadFailure = createAction(
  '[Payments] Load data failure',
  props<{ error: any }>()
);

export const loadPaymentFilters = createAction('[Payments] Load Filters');

export const loadPaymentsFiltersSucess = createAction(
  '[Payments] Load Filters success',
  props<{ result: PaymentFilters }>()
);

export const loadPaymentFiltersFailure = createAction(
  '[Payments] Load Filters failure',
  props<{ error: any }>()
);

export const loadPaymentDetail = createAction(
  '[Payments] Load Detail data',
  props<{ id: string }>()
);

export const paymentLoadDetailSucess = createAction(
  '[Payments] Load Detail data success',
  props<{ result: DetailsItem<Payment> }>()
);

export const paymentLoadDetailFailure = createAction(
  '[Payments] Load Detail data failure',
  props<{ error: any }>()
);

export const loadPaymentReceipt = createAction(
  '[Payments] Load Receipt data',
  props<{ id: string }>()
);

export const paymentLoadReceiptSucess = createAction(
  '[Payments] Load Receipt data success',
  props<{ result: PaymentReceipt }>()
);

export const paymentLoadReceiptFailure = createAction(
  '[Payments] Load Receipt data failure',
  props<{ error: any }>()
);

export const loadPaymentReceiptDownload = createAction(
  '[Payments] Load ReceiptDownload data',
  props<{ id: string }>()
);

export const paymentLoadReceiptDownloadSucess = createAction(
  '[Payments] Load ReceiptDownload data success',
  props<{ result: any; paymentId: string }>()
);

export const paymentLoadReceiptDownloadFailure = createAction(
  '[Payments] Load ReceiptDownload data failure',
  props<{ error: any }>()
);

// Refund
export const updateRefund = createAction(
  '[Refund] Create refund',
  props<{ data: PaymentRefund }>()
);

export const updateRefundSucess = createAction(
  '[Refund] Create refund Success',
  props<{ result: PaymentRefund }>()
);

export const updateRefundFailure = createAction(
  '[Refund] Create refund Failure',
  props<{ error: any }>()
);
