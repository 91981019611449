// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-filter {
  padding-bottom: 20px;
}
.table-filter .lpa-button {
  margin-top: 10px;
}

.success-icn {
  color: green;
}

.error-icn {
  color: red;
}

.search-icn .mat-icon {
  font-size: 150% !important;
}

.empty-result {
  font-size: 18px;
  text-align: center;
  padding: 30px;
}`, "",{"version":3,"sources":["webpack://./projects/lpa-backoffice/src/app/shared/parking-dialog/parkings-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,UAAA;AAGF;;AADA;EACE,0BAAA;AAIF;;AAFA;EACE,eAAA;EACA,kBAAA;EACA,aAAA;AAKF","sourcesContent":[".table-filter {\n  padding-bottom: 20px;\n  .lpa-button {\n    margin-top: 10px;\n  }\n}\n.success-icn {\n  color: green;\n}\n.error-icn {\n  color: red;\n}\n.search-icn .mat-icon {\n  font-size: 150% !important;\n}\n.empty-result {\n  font-size: 18px;\n  text-align: center;\n  padding: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
