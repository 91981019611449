
import { Action, createReducer, on } from '@ngrx/store';
import { VehiclesState } from './vehicles.models';
import { vehiclesLoadSucess, vehiclesLoadFailure, loadVehicles, loadVehiclesDetail, vehiclesDetailLoadFailure, vehiclesDetailLoadSucess, loadVehiclesFormData, vehiclesFormDataFailure, vehiclesFormDataLoadSucess, pushVehiclesDetail, vehiclesDetailPushFailure, vehiclesDetailPushSucess } from './vehicles.actions';

export const initialState: VehiclesState = {
    details: {
        loading: false,
        response: {
            data: null
        },
        error: null
    },
    list: {
        loading: false,
        response: {
            data: [],
            links: null,
            meta: null
        },
        error: null,
    },
    formData: {
        loading: false,
        error: null,
        response: null
    }
};

const reducer = createReducer(
    initialState,
    on(vehiclesLoadFailure, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: false,
                error: action.error,
                response: initialState.list.response
            }
        }
    })),
    on(vehiclesLoadSucess, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: false,
                error: null,
                response: action.result
            }
        }
    })),

    on(loadVehicles, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: true,
                error: null,
                response: state.list.response
            }
        }
    })),

    on(loadVehiclesDetail, (state, action) => ({
        ...state,
        ...{
            details: {
                loading: true,
                error: null,
                response: state.details.response
            }
        }
    })),

    on(vehiclesDetailLoadFailure, (state, action) => ({
        ...state,
        ...{
            details: {
                loading: false,
                error: action.error,
                response: initialState.details.response
            }
        }
    })),
    on(vehiclesDetailLoadSucess, (state, action) => ({
        ...state,
        ...{
            details: {
                loading: false,
                error: null,
                response: action.result
            }
        }
    })),


    on(pushVehiclesDetail, (state, action) => ({
        ...state,
        ...{
            details: {
                loading: true,
                response: state.details.response
            }
        }
    })),

    on(vehiclesDetailPushFailure, (state, action) => ({
        ...state,
        ...{
            details: {
                loading: false,
                error: action.error,
                response: state.details.response
            }
        }
    })),
    on(vehiclesDetailPushSucess, (state, action) => ({
        ...state,
        ...{
            details: {
                loading: false,
                error: null,
                response: state.details.response
            }
        }
    })),

    on(loadVehiclesFormData, (state, action) => ({
        ...state,
        ...{
            formData: {
                loading: true,
                error: null,
                response: state.formData.response
            }
        }
    })),

    on(vehiclesFormDataFailure, (state, action) => ({
        ...state,
        ...{
            formData: {
                loading: false,
                error: action.error,
                response: state.formData.response
            }
        }
    })),

    on(vehiclesFormDataLoadSucess, (state, action) => ({
        ...state,
        ...{
            formData: {
                loading: false,
                error: null,
                response: action.result
            }
        }
    })),
);



export function vehiclesReducer(
    state: VehiclesState | undefined,
    action: Action
): VehiclesState {
    return reducer(state, action);
}
