import { Parking } from './../../parkings/core/parkings.models';
import { DetailsItem } from './../../../../core/models/http-response.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse } from '../../../../core/models/http-response.models';
import { MonthlyRate, MonthlyRatesVerifyState, MonthlyRatesVerify } from './monthly-rates.models';

export const loadMonthlyRates = createAction('[Monthly Rates] Load data', props<{ page: number, q: string, sortBy: string, sortOrder: string }>());
export const monthlyRatesLoadSucess = createAction('[Monthly Rates] Load data success', props<{ result: PaginatedListResponse<MonthlyRate> }>());
export const monthlyRatesLoadFailure = createAction('[Monthly Rates] Load data failure', props<{ error: any }>());


export const loadMonthlyRate = createAction('[Monthly Rate] Load data', props<{ id: number }>());
export const monthlyRateLoadSucess = createAction('[Monthly Rate] Load data success', props<{ result: DetailsItem<MonthlyRate> }>());
export const monthlyRateLoadFailure = createAction('[Monthly Rate] Load data failure', props<{ error: any }>());

export const loadMonthlyRateParkings = createAction('[Monthly Rates Parkings] Load data', props<{ rateId: string, page: number, q: string, sortBy: string, sortOrder: string }>());
export const monthlyRateParkingsLoadSucess = createAction('[Monthly Rates Parkings] Load data success', props<{ result: PaginatedListResponse<Parking> }>());
export const monthlyRateParkingsLoadFailure = createAction('[Monthly Rates Parkings] Load data failure', props<{ error: any }>());

export const pushMonthlyRate = createAction('[Monthly Rate] Push data', props<{ data: MonthlyRate }>());
export const monthlyRatePushSucess = createAction('[Monthly Rate] Push data success', props<{ result: DetailsItem<MonthlyRate> }>());
export const monthlyRatePushFailure = createAction('[Monthly Rate] Push data failure', props<{ error: any }>());

export const verifyRate = createAction('[Monthly Rate Verify] Load data', props<{ id: number }>());
export const verifyRateSucess = createAction('[Monthly Rate Verify] Load data success', props<{ result: MonthlyRatesVerify }>());
export const verifyRateFailure = createAction('[Monthly Rate Verify] Load data failure', props<{ error: any }>());
