import { createAction, props } from '@ngrx/store';
import {
  AuthToken,
  LoginRequest,
  UserInfo,
} from './auth.model';

export const authLogout = createAction('[Auth] Logout');

export const authLogin = createAction(
  '[Auth] Login',
  props<{ loginRequest: LoginRequest }>()
);

export const authLoginSuccess = createAction(
  '[Auth] Login Success',
  props<{ authToken: AuthToken, userInfos : UserInfo }>()
);

export const authLoginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const authRefreshTokenSuccess = createAction(
  '[Auth] Login Refresh Token Success',
  props<{ authToken: AuthToken }>()
);
