// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  padding: 0;
  min-width: 36px;
  margin-left: 10px;
}
button mat-icon {
  position: relative;
  top: 2px;
  font-size: 18px;
}
button fa-icon {
  position: relative;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./projects/lpa-backoffice/src/app/shared/big-input/big-input-action/big-input-action.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,eAAA;AACJ;AACE;EACE,kBAAA;EACA,eAAA;AACJ","sourcesContent":["button {\n  padding: 0;\n  min-width: 36px;\n  margin-left: 10px;\n\n  mat-icon {\n    position: relative;\n    top: 2px;\n    font-size: 18px;\n  }\n  fa-icon {\n    position: relative;\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
