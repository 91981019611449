import { Promo } from './promo-codes.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';

export const loadPromo = createAction('[Promo] Load data', props<{ page: number,  q: string, sortBy: string, sortOrder: string }>());

export const PromoLoadSucess = createAction('[Promo] Load data success', props<{ result: PaginatedListResponse<Promo> }>());

export const PromoLoadFailure = createAction('[Promo] Load data failure', props<{ error: any }>());

// Detail promo
export const loadPromoDetail = createAction('[Promo] Load Detail data', props<{ id: string }>());

export const promoLoadDetailSucess = createAction('[Promo] Load Detail data success', props<{ result: DetailsItem<Promo> }>());

export const promoLoadDetailFailure = createAction('[Promo] Load Detail data failure', props<{ error: any }>());

// Delete promo
export const deletePromo = createAction('[Promo] delete promo data', props<{ id: string }>());

export const promoDeleteSucess = createAction('[Promo] delete promo data success', props<{ result: any }>());

export const promoDeleteFailure = createAction('[Promo] delete promo data failure', props<{ error: any }>());


// Promo creation 
export const creatPromo = createAction('[Promo] Create promo', props<{ data: Promo }>());

export const creatPromoSucess = createAction('[Promo] Create promo Success', props<{ result: DetailsItem<Promo> }>());

export const creatPromoFailure = createAction('[Promo] Create promo Failure', props<{ error: any }>());