import { Dashboard, Graph } from './dashboard.models';
import { createAction, props } from '@ngrx/store';
import {  DetailsItem } from '../../../../core/models/http-response.models';



// Dashboard statistics 
export const statisticsDetail = createAction('[Dashboard] Create dashboard');
export const statisticsDetailSucess = createAction('[Dashboard] Create dashboard Success', props<{ result: DetailsItem<Dashboard> }>());
export const statisticsDetailFailure = createAction('[Dashboard] Create dashboard Failure', props<{ error: any }>());

// Dashboard graphs 
export const loadGraph = createAction('[Dashboard] Load Graph');
export const loadGraphSucess = createAction('[Dashboard] Load Graph Success', props<{ result: DetailsItem<Graph> }>());
export const loadGraphFailure = createAction('[Dashboard] Load Graph Failure', props<{ error: any }>());