// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-input {
  width: 100%;
  transition: all 0.5s;
  display: flex;
  padding: 10px 10px 10px 20px;
}
.big-input input {
  flex-grow: 1;
  border: 0;
  font-size: 20px;
  min-width: 100px;
}`, "",{"version":3,"sources":["webpack://./projects/lpa-backoffice/src/app/shared/big-input/big-input/big-input.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;EACA,aAAA;EACA,4BAAA;AACF;AACE;EACE,YAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".big-input {\n  width: 100%;\n  transition: all 0.5s;\n  display: flex;\n  padding: 10px 10px 10px 20px;\n\n  input {\n    flex-grow: 1;\n    border: 0;\n    font-size: 20px;\n    min-width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
