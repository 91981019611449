import { Cms } from './cms.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';

// Cms load 
export const loadCms = createAction('[Cms] Load data', props<{ sortBy: string, sortOrder: string }>());

export const CmsLoadSucess = createAction('[Cms] Load data success', props<{ result: PaginatedListResponse<Cms> }>());

export const CmsLoadFailure = createAction('[Cms] Load data failure', props<{ error: any }>());


// Cms detail 
export const loadCmsDetail = createAction('[Cms] Load Detail data', props<{ id: string }>());

export const cmsLoadDetailSucess = createAction('[Cms] Load Detail data success', props<{ result: DetailsItem<Cms> }>());

export const cmsLoadDetailFailure = createAction('[Cms] Load Detail data failure', props<{ error: any }>());



// Cms creation 
export const creatCms = createAction('[Cms] Create cms', props<{ data: Cms }>());

export const creatCmsSucess = createAction('[Cms] Create cms Success', props<{ result: DetailsItem<Cms> }>());

export const creatCmsFailure = createAction('[Cms] Create cms Failure', props<{ error: any }>());