import { Injectable, NgZone } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig
} from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {}

  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  info(message: string, duration = 2000) {
    this.show(message, {
      duration,
      panelClass: 'info-notification-overlay'
    });
  }

  infoWithAction(
    message: string,
    action = null,
    actionHandler = null,
    duration = 2000
  ) {
    this.showWithAction(message, action, actionHandler, {
      duration,
      panelClass: 'info-notification-overlay'
    });
  }

  success(message: string, duration = 2000) {
    return this.show(message, {
      duration,
      panelClass: 'success-notification-overlay'
    });
  }

  successWithAction(
    message: string,
    action = null,
    actionHandler = null,
    duration = 3000
  ) {
    this.showWithAction(message, action, actionHandler, {
      duration,
      panelClass: 'success-notification-overlay'
    });
  }

  warn(message: string, duration = 2000) {
    this.show(message, {
      duration,
      panelClass: 'warning-notification-overlay'
    });
  }

  warnWithAction(
    message: string,
    action = null,
    actionHandler = null,
    duration = 3000
  ) {
    this.showWithAction(message, action, actionHandler, {
      duration,
      panelClass: 'warning-notification-overlay'
    });
  }

  error(message: string, duration = 3000) {
    return this.show(message, {
      duration,
      panelClass: 'error-notification-overlay'
    });
  }

  errorWithAction(
    message: string,
    action = null,
    actionHandler = null,
    duration = 3000
  ) {
    this.showWithAction(message, action, actionHandler, {
      duration,
      panelClass: 'error-notification-overlay'
    });
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }
  private showWithAction(
    message: string,
    action,
    actionHandler,
    configuration: MatSnackBarConfig
  ) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => {
      this.snackBar
        .open(message, action, configuration)
        .onAction()
        .subscribe(actionHandler);
    });
  }
}
