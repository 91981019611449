import { Action, createReducer, on } from '@ngrx/store';
import { CmsState } from './cms.models';
import { CmsLoadFailure, CmsLoadSucess, loadCms, loadCmsDetail, cmsLoadDetailSucess, cmsLoadDetailFailure, creatCms, creatCmsSucess, creatCmsFailure } from './cms.actions';

export const initialState: CmsState = {
    list: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        }
        
    },
    detail: {
        loading: false,
        error: null,
        list: {
            data: null,
        }
    }

};
const reducer = createReducer(
    initialState,
    on(CmsLoadSucess, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: false,
                error: null,
                list: action.result,
            }
        }
    })),
    on(CmsLoadFailure, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },

            }
        }
    })),
    on(loadCms, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: true,
                error: null,
                list: initialState.list.list,
            }
        }
    })),
  

    on(loadCmsDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(cmsLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(cmsLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),
    on(creatCms, (state, action) => ({
        ...state,
        ...{
            loading: true,
            error: null
        }
    })),
    on(creatCmsSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: action.result
            }
        }
    })),
    on(creatCmsFailure, (state, action) => ({
        ...state,
        ...{
            loading: false,
            error: action.error
        }
    }))
)
export function cmsReducer(
    state: CmsState | undefined,
    action: Action
): CmsState {
    return reducer(state, action);
}