import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  Parking,
  parkingStatusLabels
} from '../../features/home/parkings/core/parkings.models';

@Component({
  selector: 'lpa-confirm-dialog',
  templateUrl: 'parkings-dialog.component.html',
  styleUrls: ['parkings-dialog.component.scss']
})
export class ParkingsDialogComponent implements OnInit {
  static COLUMNS_MAPPING = {
    parking: 'name',
    city: 'city',
    partner: 'partner',
    creation: 'created_at',
    demat: 'dematerialized',
    status: 'status'
  };
  STATUS_LABELS = parkingStatusLabels;
  dataSource = new MatTableDataSource<Parking>([]);
  displayedColumns: string[] = [
    'parking',
    'city',
    'partner',
    'creation',
    'demat',
    'status'
  ];

  constructor(
    public dialogRef: MatDialogRef<ParkingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dataSource.data = this.data.data; // Assign data to dataSource.data
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
