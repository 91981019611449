import { MonthlyRate } from './../../../monthly-rates/core/monthly-rates.models';
import { createAction, props } from '@ngrx/store';

import {
  ParkingDetailsFormData,
  ParkingAccessPosition,
  ParkingLocation,
  Partner,
  ParkingCustomer,
  ParkingLocationUpdate,
  ParkingPrices,
  ParkingOpenGate,
  ParkingQuota,
  ParkingWaiting,
  ParkingSpace,
  ImpactedCustomers,
  QuotasResponse,
  AccessState
} from './parking.details.models';
import { Parking, ParkingAccess } from '../parkings.models';
import {
  DetailsItem,
  PaginatedListResponse
} from '../../../../../core/models/http-response.models';

import { SelectedSpaceFilters } from '../../core/details/parking.details.models';

export const loadParking = createAction(
  '[Parking] Load data',
  props<{ id: string }>()
);

export const parkingLoadSucess = createAction(
  '[Parking] Load data success',
  props<{ result: DetailsItem<Parking> }>()
);

export const parkingLoadFailure = createAction(
  '[Parking] Load data failure',
  props<{ error: any }>()
);

// Creat edit parking
export const parkingCreate = createAction(
  '[Parking] Create parking',
  props<{ data: Parking }>()
);

export const parkingCreateSucess = createAction(
  '[Parking] Create parking Success',
  props<{ result: DetailsItem<Parking> }>()
);

export const parkingCreateFailure = createAction(
  '[Parking] Create parking Failure',
  props<{ error: any }>()
);

// Load parking form data
export const loadFormData = createAction('[Parkings] Load FormData');

export const loadFormDataSucess = createAction(
  '[Parkings] Load FormData success',
  props<{ result: ParkingDetailsFormData }>()
);

export const loadFormDataFailure = createAction(
  '[Parkings] Load FormData failure',
  props<{ error: any }>()
);

// Load parking access
export const loadParkingAccess = createAction(
  '[Parking] Load access data',
  props<{ id: string }>()
);
export const parkingAccessLoadSucess = createAction(
  '[Parking] Load access data success',
  props<{ result: PaginatedListResponse<ParkingAccess> }>()
);
export const parkingAccessLoadFailure = createAction(
  '[Parking] Load access data failure',
  props<{ error: any }>()
);

// Create parking access
export const createParkingAccess = createAction(
  '[Parking] Create access data',
  props<{ parkingId: string; data: ParkingAccess }>()
);
export const parkingAccessCreationSucess = createAction(
  '[Parking] Create access data success',
  props<{ result: DetailsItem<ParkingAccess>; isUpdate: boolean }>()
);
export const parkingAccessCreationFailure = createAction(
  '[Parking] Create access data failure',
  props<{ error: any }>()
);

// Delete parking access
export const deleteParkingAccess = createAction(
  '[Parking] Delete access data',
  props<{ parkingId: string; accessId: string }>()
);
export const parkingAccessDeletionSucess = createAction(
  '[Parking] Delete access data success',
  props<{ result: any }>()
);
export const parkingAccessDeletionFailure = createAction(
  '[Parking] Delete access data failure',
  props<{ error: any }>()
);

// Create  partner
export const partnerCreate = createAction(
  '[Partner] Create partner',
  props<{ data: Partner }>()
);
export const partnerCreateSucess = createAction(
  '[Partner] Create partner Success',
  props<{ result: Partner }>()
);
export const partnerCreateFailure = createAction(
  '[Partner] Create partner Failure',
  props<{ error: any }>()
);

// Post parking access positions
export const postParkingAccessPositions = createAction(
  '[Parking] Post access positions data',
  props<{ parkingId: string; positions: ParkingAccessPosition[] }>()
);
export const parkingAccessPositionsSucess = createAction(
  '[Parking] Post access positions data success',
  props<{ result: any }>()
);
export const parkingAccessPositionsFailure = createAction(
  '[Parking] Post access positions data failure',
  props<{ error: any }>()
);

// parking location
export const loadParkingLocation = createAction(
  '[Parking] Retrieve location data',
  props<{ parkingId: string }>()
);
export const parkingLocationLoadSucess = createAction(
  '[Parking] Retrieve location data success',
  props<{ result: ParkingLocation }>()
);
export const parkingLocationLoadFailure = createAction(
  '[Parking] Retrieve location data failure',
  props<{ error: any }>()
);

// Update location
export const locationCreate = createAction(
  '[Parking] Create location',
  props<{ data: ParkingLocationUpdate }>()
);
export const locationCreateSucess = createAction(
  '[Parking] Create location Success',
  props<{ result: DetailsItem<ParkingLocationUpdate> }>()
);
export const locationCreateFailure = createAction(
  '[Parking] Create location Failure',
  props<{ error: any }>()
);

// parking customers
export const loadParkingCustomers = createAction(
  '[Parking] Retrieve customers data',
  props<{
    parkingId: string;
    page?: number;
    q: string;
    sortBy?: string;
    sortOrder?: string;
    all?: string;
  }>()
);
export const parkingCustomersLoadSucess = createAction(
  '[Parking] Retrieve customers data success',
  props<{ result: PaginatedListResponse<ParkingCustomer> }>()
);
export const parkingCustomersLoadFailure = createAction(
  '[Parking] Retrieve customers data failure',
  props<{ error: any }>()
);

// parking prices
export const loadParkingPrices = createAction(
  '[Parking] Retrieve prices data',
  props<{ parkingId: string }>()
);
export const parkingPricesLoadSucess = createAction(
  '[Parking] Retrieve prices data success',
  props<{ result: DetailsItem<ParkingPrices> }>()
);
export const parkingPricesLoadFailure = createAction(
  '[Parking] Retrieve prices data failure',
  props<{ error: any }>()
);

// parking monthly rate
export const loadParkingMonthlyRates = createAction(
  '[Parking] Retrieve MonthlyRates data',
  props<{
    parkingId: string;
    page: number;
    q: string;
    sortBy: string;
    sortOrder: string;
  }>()
);
export const parkingMonthlyRatesLoadSucess = createAction(
  '[Parking] Retrieve MonthlyRates data success',
  props<{ result: PaginatedListResponse<MonthlyRate> }>()
);
export const parkingMonthlyRatesLoadFailure = createAction(
  '[Parking] Retrieve MonthlyRates data failure',
  props<{ error: any }>()
);

// parking prices update
export const updateParkingPrices = createAction(
  '[Parking] Update prices data',
  props<{ parkingId: string; data: ParkingPrices }>()
);
export const parkingPricesUpdateSucess = createAction(
  '[Parking] Update prices data success',
  props<{ result: DetailsItem<Parking> }>()
);
export const parkingPricesUpdateFailure = createAction(
  '[Parking] Update prices data failure',
  props<{ error: any }>()
);

// Update parking access gate
export const openGate = createAction(
  '[Parking] open gate data',
  props<{ parkingId: string; gateId: string; data: ParkingOpenGate }>()
);
export const openGateSucess = createAction(
  '[Parking] open gate data success',
  props<{ result: DetailsItem<ParkingOpenGate>; isUpdate: boolean }>()
);
export const openGateFailure = createAction(
  '[Parking] open gate data failure',
  props<{ error: any }>()
);
export const openGateEmpty = createAction('[Parking] open gate data empty');

// parking Quotas retrieve
export const loadParkingQuotas = createAction(
  '[Parking] Retrieve quotas data',
  props<{ parkingId: string }>()
);
export const parkingQuotasLoadSucess = createAction(
  '[Parking] Retrieve quotas data success',
  props<{ result: QuotasResponse }>()
);
export const parkingQuotasLoadFailure = createAction(
  '[Parking] Retrieve quotas data failure',
  props<{ error: any }>()
);

// parking Quotas delete
export const deleteParkingQuota = createAction(
  '[Parking] Delete quotas data',
  props<{ parkingId: string; quotaId: string }>()
);
export const parkingQuotasDeleteSucess = createAction(
  '[Parking] Delete quotas data success',
  props<{ result: any }>()
);
export const parkingQuotasDeleteFailure = createAction(
  '[Parking] Delete quotas data failure',
  props<{ error: any }>()
);

// parking Quotas create
export const pushParkingQuota = createAction(
  '[Parking] Push quotas data',
  props<{ parkingId: string; data: ParkingQuota }>()
);
export const parkingQuotasPushSucess = createAction(
  '[Parking] Push quotas data success',
  props<{ result: any }>()
);
export const parkingQuotasPushFailure = createAction(
  '[Parking] Push quotas data failure',
  props<{ error: any }>()
);

// parking Quotas impacts retrieve
export const loadParkingQuotaImpacts = createAction(
  '[Parking] Retrieve quotas impacts data',
  props<{ parkingId: string; quotaId: string }>()
);
export const parkingQuotaImpactsLoadSucess = createAction(
  '[Parking] Retrieve quotas impacts data success',
  props<{ result: ImpactedCustomers }>()
);
export const parkingQuotaImpactsLoadFailure = createAction(
  '[Parking] Retrieve quotas impacts data failure',
  props<{ error: any }>()
);

// parking Waiting retrieve
export const loadParkingWaiting = createAction(
  '[Parking] Retrieve waiting data',
  props<{
    parkingId: string;
    page: number;
    q: string;
    sortBy: string;
    sortOrder: string;
  }>()
);
export const parkingWaitingLoadSucess = createAction(
  '[Parking] Retrieve waiting data success',
  props<{ result: PaginatedListResponse<ParkingWaiting> }>()
);
export const parkingWaitingLoadFailure = createAction(
  '[Parking] Retrieve waiting data failure',
  props<{ error: any }>()
);

// parking Space retrieve
export const loadParkingSpace = createAction(
  '[Parking] Retrieve space data',
  props<{
    parkingId: string;
    page?: number;
    sortBy?: string;
    sortOrder?: string;
    filters?: SelectedSpaceFilters;
    limit?: number;
  }>()
);
export const parkingSpaceLoadSucess = createAction(
  '[Parking] Retrieve space data success',
  props<{ result: PaginatedListResponse<ParkingSpace> }>()
);
export const parkingSpaceLoadFailure = createAction(
  '[Parking] Retrieve space data failure',
  props<{ error: any }>()
);

// parking Waiting delete
export const deleteParkingWaiting = createAction(
  '[Parking] Delete waiting data',
  props<{ parkingId: string; waitingId: string }>()
);
export const parkingWaitingDeleteSucess = createAction(
  '[Parking] Delete waiting data success',
  props<{ result: any }>()
);
export const parkingWaitingDeleteFailure = createAction(
  '[Parking] Delete waiting data failure',
  props<{ error: any }>()
);

// parking Space delete
export const deleteParkingSpace = createAction(
  '[Parking] Delete spaces data',
  props<{ parkingId: string; spaceId: string }>()
);
export const parkingSpacesDeleteSucess = createAction(
  '[Parking] Delete spaces data success',
  props<{ result: any }>()
);
export const parkingSpacesDeleteFailure = createAction(
  '[Parking] Delete spaces data failure',
  props<{ error: any }>()
);

// Create parking space
export const createParkingSpace = createAction(
  '[Parking] Create space data',
  props<{ parkingId: string; page: number; data: ParkingSpace }>()
);
export const parkingSpaceCreationSucess = createAction(
  '[Parking] Create space data success',
  props<{ result: DetailsItem<ParkingSpace>; isUpdate: boolean }>()
);
export const parkingSpaceCreationFailure = createAction(
  '[Parking] Create space data failure',
  props<{ error: any }>()
);

// Update access
export const saveAccessStatus = createAction(
  '[Parking] update access',
  props<{ parkingId: string; data: AccessState }>()
);
export const saveAccessStatusSucess = createAction(
  '[Parking] update access success',
  props<{ result: any }>()
);
export const saveAccessStatusFailure = createAction(
  '[Parking] update access failure',
  props<{ error: any }>()
);
