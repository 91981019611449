import { BookingFilters, Booking, SelectedFilters, Gate } from './bookings.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';

export const loadBookings = createAction('[Bookings] Load data', props<{ page: number, filters: SelectedFilters, q: string, sortBy: string, sortOrder: string }>());

export const BookingsLoadSucess = createAction('[Bookings] Load data success', props<{ result: PaginatedListResponse<Booking> }>());

export const BookingsLoadFailure = createAction('[Bookings] Load data failure', props<{ error: any }>());


export const loadBookingFilters = createAction('[Bookings] Load Filters');

export const loadBookingsFiltersSucess = createAction('[Bookings] Load Filters success', props<{ result: BookingFilters }>());

export const loadBookingFiltersFailure = createAction('[Bookings] Load Filters failure', props<{ error: any }>());


export const loadBookingDetail = createAction('[Bookings] Load Detail data', props<{ id: string }>());

export const bookingLoadDetailSucess = createAction('[Bookings] Load Detail data success', props<{ result: DetailsItem<Booking> }>());

export const bookingLoadDetailFailure = createAction('[Bookings] Load Detail data failure', props<{ error: any }>());


export const loadGates = createAction('[Bookings] Load Gates', props<{ id: string, sortBy: string, sortOrder: string, page: number, source: string}>());
export const loadGatesSucess = createAction('[Bookings] Load Gates success', props<{ result: PaginatedListResponse<Gate> }>());
export const loadGatesFailure = createAction('[Bookings] Load Gates failure', props<{ error: any }>());


export const cancelExcess = createAction('[Bookings] Cancel Excess', props<{ id: string}>());
export const cancelExcessSucess = createAction('[Bookings] Cancel Excess success', props<{ result: any }>());
export const cancelExcessFailure = createAction('[Bookings] Cancel Excess failure', props<{ error: any }>());
