import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'lpa-big-input',
  templateUrl: './big-input.component.html',
  styleUrls: ['./big-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BigInputComponent {
  @Input()
  placeholder: string;

  @Input()
  value = '';
  @Input()
  disabled = false;

  hasFocus = false;

  @Output()
  enterPressed = new EventEmitter();

  @Output()
  valueChanged = new EventEmitter();

  onInput(event: any) {
    this.value = event.target.value;
    this.valueChanged.emit(this.value);
  }
  onKeydown(event) {
    if (event.key === 'Enter') {
      this.enterPressed.emit(this.value);
    }
  }
}
