import { Action, createReducer, on } from '@ngrx/store';
import { ExportState } from './export.models';
import { creatExport, creatExportSucess, creatExportFailure } from './export.actions';

export const initialState: ExportState = {
    loading: false,
    error: null,
    export: null
};
const reducer = createReducer(
    initialState,

    on(creatExport, (state, action) => ({
        ...state,
        ...{
            loading: true,
            error: null,
            export: initialState.export
        }
    })),
    on(creatExportSucess, (state, action) => ({
        ...state,
        ...{

            loading: false,
            error: null,
            export: action.result

        }
    })),
    on(creatExportFailure, (state, action) => ({
        ...state,
        ...{
            loading: false,
            error: action.error,
            export: initialState.export
        }
    }))
)
export function exportReducer(
    state: ExportState | undefined,
    action: Action
): ExportState {
    return reducer(state, action);
}