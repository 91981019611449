import { Export } from './export.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';



// Export creation 
export const creatExport = createAction('[Export] Create export', props<{ data: Export }>());

export const creatExportSucess = createAction('[Export] Create export Success', props<{ result: any }>());

export const creatExportFailure = createAction('[Export] Create export Failure', props<{ error: any }>());