// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lpa-overlay {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.lpa-overlay .lpa-overlay-progress {
  position: relative;
  width: 100%;
  height: 100%;
}
.lpa-overlay .loading-popin {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  padding: 5px;
  width: 200px;
  margin: auto;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./projects/lpa-backoffice/src/app/shared/loading-overlay/loading-overlay.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,aAAA;AACJ;AAEI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AAAR;AAEI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;AAAR","sourcesContent":[".lpa-overlay{\n    position: fixed;\n    top: 64px;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0,0,0,0.5); \n    z-index: 1000;\n\n  \n    .lpa-overlay-progress{\n        position: relative;\n        width: 100%;\n        height: 100%;\n    }\n    .loading-popin{\n        position: absolute;\n        bottom: 0;\n        right: 0;\n        text-align: center;\n        padding: 5px;\n        width: 200px;\n        margin : auto;\n        font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
