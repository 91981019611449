import { UserFilters, User, SelectedFilters } from './users.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';

export const loadUsers = createAction('[Users] Load data', props<{ page: number, filters: SelectedFilters, q: string, sortBy: string, sortOrder: string }>());

export const UsersLoadSucess = createAction('[Users] Load data success', props<{ result: PaginatedListResponse<User> }>());

export const UsersLoadFailure = createAction('[Users] Load data failure', props<{ error: any }>());


export const loadUserFilters = createAction('[Users] Load Filters');

export const loadUsersFiltersSucess = createAction('[Users] Load Filters success', props<{ result: UserFilters }>());

export const loadUserFiltersFailure = createAction('[Users] Load Filters failure', props<{ error: any }>());


export const loadUserDetail = createAction('[Users] Load Detail data', props<{ id: string }>());

export const userLoadDetailSucess = createAction('[Users] Load Detail data success', props<{ result: DetailsItem<User> }>());

export const userLoadDetailFailure = createAction('[Users] Load Detail data failure', props<{ error: any }>());

export const deleteUser = createAction('[Users] delete user data', props<{ id: string }>());
export const userDeleteSucess = createAction('[Users] delete user data success', props<{ result: any }>());
export const userDeleteFailure = createAction('[Users] delete user data failure', props<{ error: any }>());



// User creation 
export const creatUser = createAction('[User] Create user', props<{ data: User }>());

export const creatUserSucess = createAction('[User] Create user Success', props<{ result: DetailsItem<User> }>());

export const creatUserFailure = createAction('[User] Create user Failure', props<{ error: any }>());