import { SubscriptionFilters, Subscriptions, SelectedFilters, SubscriptionAccess, SubscriptionCancelDates } from './subscriptions.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse, DetailsItem } from '../../../../core/models/http-response.models';

export const loadSubscriptions = createAction('[Subscriptions] Load data', props<{ page: number, filters: SelectedFilters, q: string, sortBy: string, sortOrder: string }>());

export const SubscriptionsLoadSucess = createAction('[Subscriptions] Load data success', props<{ result: PaginatedListResponse<Subscriptions> }>());

export const SubscriptionsLoadFailure = createAction('[Subscriptions] Load data failure', props<{ error: any }>());


export const loadSubscriptionFilters = createAction('[Subscriptions] Load Filters');

export const loadSubscriptionsFiltersSucess = createAction('[Subscriptions] Load Filters success', props<{ result: SubscriptionFilters }>());

export const loadSubscriptionFiltersFailure = createAction('[Subscriptions] Load Filters failure', props<{ error: any }>());


export const loadSubscriptionDetail = createAction('[Subscriptions] Load Detail data', props<{ id: string }>());

export const subscriptionLoadDetailSucess = createAction('[Subscriptions] Load Detail data success', props<{ result: DetailsItem<Subscriptions> }>());

export const subscriptionLoadDetailFailure = createAction('[Subscriptions] Load Detail data failure', props<{ error: any }>());


// Subscription creation 
export const creatSubscription = createAction('[Subscription] Create subscription', props<{ data: Subscriptions }>());

export const creatSubscriptionSucess = createAction('[Subscription] Create subscription Success', props<{ result: DetailsItem<Subscriptions> }>());

export const creatSubscriptionFailure = createAction('[Subscription] Create subscription Failure', props<{ error: any }>());


// Access 
export const updateAccess = createAction('[Access] Create acess', props<{ data: Subscriptions }>());

export const updateAccessSucess = createAction('[Access] Create acess Success', props<{ result: SubscriptionAccess }>());

export const updateAccessFailure = createAction('[Access] Create acess Failure', props<{ error: any }>());

// Cancel dates
export const loadSubscriptionCancelDates = createAction('[Subscriptions] Load CancelDates', props<{ id: string }>());

export const loadSubscriptionCancelDatesSucess = createAction('[Subscriptions] Load CancelDates Success', props<{ result: SubscriptionCancelDates }>());

export const loadSubscriptionCancelDatesFailure = createAction('[Subscriptions] Load CancelDates failure', props<{ error: any }>());


// Cancel subscription
export const cancelSubscription = createAction('[Subscription] Create cancelSubscription', props<{ data: Subscriptions }>());

export const cancelSubscriptionSucess = createAction('[Subscription] Create cancelSubscription Success', props<{ result: SubscriptionCancelDates }>());

export const cancelSubscriptionFailure = createAction('[Subscription] Create cancelSubscription Failure', props<{ error: any }>());

// Create penality
export const createPenality = createAction('[Subscription] Create penality', props<{ id: string, price: number }>());

export const createPenalitySucess = createAction('[Subscription] Create penality Success', props<{ result: any }>());

export const createPenalityFailure = createAction('[Subscription] Create penality Failure', props<{ error: any }>());