import { Action, createReducer, on } from '@ngrx/store';
import { ParkingsState } from './parkings.models';
import {
  parkingsLoadSucess,
  parkingsLoadFailure,
  loadParkings,
  loadFilters,
  loadFiltersSucess,
  loadFiltersFailure
} from './parkings.actions';

export const initialState: ParkingsState = {
  loading: false,
  error: null,
  filters: {
    list: {
      cities: null,
      partners: null,
      statutes: null,
      types: null
    },
    loading: false,
    error: null
  },
  list: {
    data: [],
    links: null,
    meta: null
  }
};

const reducer = createReducer(
  initialState,
  on(parkingsLoadSucess, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: null,
      list: action.result
    }
  })),
  on(parkingsLoadFailure, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error,
      list: {
        data: [],
        links: null,
        meta: null
      }
    }
  })),

  on(loadFilters, (state, action) => ({
    ...state,
    ...{
      filters: {
        loading: true,
        error: null,
        list: state.filters.list
      }
    }
  })),

  on(loadFiltersSucess, (state, action) => ({
    ...state,
    ...{
      filters: {
        loading: false,
        error: null,
        list: action.result
      }
    }
  })),

  on(loadFiltersFailure, (state, action) => ({
    ...state,
    ...{
      filters: {
        loading: false,
        error: action.error,
        list: initialState.filters.list
      }
    }
  })),
  on(loadParkings, (state, action) => ({
    ...state,
    ...{
      loading: true
    }
  }))
);

export function parkingsReducer(
  state: ParkingsState | undefined,
  action: Action
): ParkingsState {
  return reducer(state, action);
}
