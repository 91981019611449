import { Action, createReducer, on } from '@ngrx/store';
import { PaymentsState } from './payments.models';
import { PaymentsLoadFailure, PaymentsLoadSucess, loadPayments, loadPaymentFilters, loadPaymentsFiltersSucess, loadPaymentFiltersFailure, loadPaymentDetail, paymentLoadDetailSucess, paymentLoadDetailFailure, loadPaymentReceipt, paymentLoadReceiptSucess, paymentLoadReceiptFailure, loadPaymentReceiptDownload, paymentLoadReceiptDownloadSucess, paymentLoadReceiptDownloadFailure } from './payments.actions';

export const initialState: PaymentsState = {
    payments: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        },
        filters: {
            loading: false,
            error: null,
            listFilter: {
                statuses: null,
                completions: null
            }
        }
    },
    detail: {
        loading: false,
        error: null,
        list: {
            data: null,
        }
    },
    receipt: {
        loading: false,
        error: null,
        success: null
    },
    receiptDownload: {
        loading: false,
        error: null,
        file: null
    }

};
const reducer = createReducer(
    initialState,
    on(PaymentsLoadSucess, (state, action) => ({
        ...state,
        ...{
            payments: {
                loading: false,
                error: null,
                list: action.result,
                filters: initialState.payments.filters
            }
        }
    })),
    on(PaymentsLoadFailure, (state, action) => ({
        ...state,
        ...{
            payments: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.payments.filters
            }
        }
    })),
    on(loadPayments, (state, action) => ({
        ...state,
        ...{
            payments: {
                loading: true,
                error: null,
                list: initialState.payments.list,
                filters: initialState.payments.filters
            }
        }
    })),
    on(loadPaymentFilters, (state, action) => ({
        ...state,
        ...{
            payments: {
                loading: true,
                error: null,
                list: initialState.payments.list,
                filters: {
                    loading: true,
                    error: null,
                    listFilter: state.payments.filters.listFilter
                }
            }
        }
    })),

    on(loadPaymentsFiltersSucess, (state, action) => ({
        ...state,
        ...{
            payments: {
                loading: false,
                error: null,
                list: initialState.payments.list,
                filters: {
                    loading: false,
                    error: null,
                    listFilter: action.result
                }
            }
        }
    })),

    on(loadPaymentFiltersFailure, (state, action) => ({
        ...state,
        ...{
            payments: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.payments.filters
            }
        }
    })),


    on(loadPaymentDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(paymentLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(paymentLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),

    on(loadPaymentReceipt, (state, action) => ({
        ...state,
        ...{
            receipt: {
                loading: true,
                error: null,
                success: initialState.receipt.success
            }
        }
    })),

    on(paymentLoadReceiptSucess, (state, action) => ({
        ...state,
        ...{
            success: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(paymentLoadReceiptFailure, (state, action) => ({
        ...state,
        ...{
            success: {
                loading: false,
                error: action.error,
                list: initialState.receipt.success
            }
        }
    })),

    on(loadPaymentReceiptDownload, (state, action) => ({
        ...state,
        ...{
            receiptDownload: {
                loading: true,
                error: null,
                file: initialState.receiptDownload.file
            }
        }
    })),

    on(paymentLoadReceiptDownloadSucess, (state, action) => ({
        ...state,
        ...{
            receiptDownload: {
                loading: false,
                error: null,
                file: action.result
            }
        }
    })),

    on(paymentLoadReceiptDownloadFailure, (state, action) => ({
        ...state,
        ...{
            receiptDownload: {
                loading: false,
                error: action.error,
                file: initialState.receiptDownload.file
            }
        }
    })),
)
export function paymentsReducer(
    state: PaymentsState | undefined,
    action: Action
): PaymentsState {
    return reducer(state, action);
}