// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require('../../../../package.json');

export const environment = {
  appName: 'LPA and CO [DEV]',
  envName: 'DEV',
  production: false,
  test: false,
  i18nPrefix: '',
  versions: {
    app: '4.0.2-dev'
  },
  keys: {
    google: {
      apiKey: 'AIzaSyBp_tFkcmI84-OFP74XVnJv41X5i2q2Nc0'
    }
  },
  api: {
    BASE_URL: 'https://backoffice.lpa-dev.bewizyu.com/api/',
    // BASE_URL: 'http://localhost:8000/api/',
    API_VERSION: 'v1',
    endpoints: {
      global: {
        HOLYDAYS: '/bankHolidays'
      },
      auth: {
        LOGIN: '/login/login',
        ME: '/login/me',
        LOGOUT: '/login/logout',
        REFRESH_TOKEN: '/login/refreshToken'
      },
      dashboard: {
        STATISTICS: '/dashboard/statistics',
        GRAPH: '/dashboard/graphes'
      },
      bookings: {
        LIST: '/bookings',
        DETAILS: '/bookings/{booking}',
        FILTERS: '/bookings/filters',
        GATES: '/bookings/{booking}/gates/history',
        EXCESS: '/bookings/{booking}/cancelExcess'
      },
      payments: {
        LIST: '/payments',
        DETAILS: '/payments/{payment}',
        FILTERS: '/payments/filters',
        RECEIPT: '/payments/{payment}/send',
        RECEIPTDOWNLOAD: '/payments/{payment}/download',
        REFUND: '/payments/{payment}/refund'
      },

      prices: {
        bookings: {
          LIST: '/prices/bookings',
          DETAILS: '/prices/bookings/{price}',
          PRICES: '/prices/bookings/{price}/rates',
          SYNC_PRICES: '/prices/bookings/{price}/syncRates',
          PARKINGS: '/prices/bookings/{price}/parkings',
          SIMULATE: '/prices/{price}/simulate'
        },
        subscriptions: {
          LIST: '/prices/subscriptions',
          DETAILS: '/prices/subscriptions/{price}',
          PARKINGS: '/prices/subscriptions/{price}/parkings',
          VERIFY: '/prices/subscriptions/{price}/verify'
        }
      },
      parkings: {
        LIST: '/parkings',
        DETAILS: '/parkings/{parking}',
        ADRESSES: '/parkings/{parking}/addresses',
        ADRESSESPUT: '/parkings/{parking}/addresses/updates',
        GATES: '/parkings/{parking}/gates',
        GATE_DETAILS: '/parkings/{parking}/gates/{gate}',
        GATE_OPEN: '/parkings/{parking}/gates/{gate}/open',
        GATE_ORDER: '/parkings/{parking}/gates/order',
        CUSTOMERS: '/parkings/{parking}/customers',
        QUOTAS: '/parkings/{parking}/quotas',
        DETAILS_QUOTA: '/parkings/{parking}/quotas/{quota}',
        FILTERS: '/parkings/filters',
        FORMDATA: '/parkings/formData',
        PRICES: '/parkings/{parking}/prices',
        WAITING: '/parkings/{parking}/waiting',
        WAITING_DETAILS: '/parkings/{parking}/waiting/{waiting}',
        SPACES: '/parkings/{parking}/spaces',
        SPACE_DETAILS: '/parkings/{parking}/spaces/{space}',
        SUBSCRIPTIONS: '/parkings/{parking}/prices/subscriptions',
        ACCESS: '/parkings/{parking}/access',
        COMMENT_INTERNAL: '/parkings/{parking}/internalcomment',
        QUOTAS_COMMENT: '/parkings/{parking}/quotacomment'
      },
      partners: {
        LIST: '/partners'
      },
      users: {
        LIST: '/users',
        DETAILS: '/users/{user}',
        FILTERS: '/users/filters'
      },
      promo: {
        LIST: '/prices/discounts',
        DETAILS: '/prices/discounts/{discount}'
      },
      vehicles: {
        LIST: '/vehicles',
        DETAILS: '/vehicles/{vehicle}',
        FORM_DATA: '/vehicles/formData'
      },
      customers: {
        LIST: '/customers',
        DETAILS: '/customers/{customer}',
        RESET_PASSWORD: '/customers/{customer}/newPassword'
      },
      cms: {
        LIST: '/pages',
        DETAILS: '/pages/{page}',
        IMAGE: '/pages/image'
      },
      subscriptions: {
        LIST: '/bookings/subscriptions',
        DETAILS: '/bookings/subscriptions/{booking}',
        FILTERS: '/bookings/subscriptions/filters',
        ACCESS: '/bookings/subscriptions/{subscription}/dematerializedAccess',
        CANCEL: '/bookings/subscriptions/{subscription}/cancel',
        DATES: '/bookings/subscriptions/{subscription}/cancel/dates',
        PENALITY: '/bookings/subscriptions/{subscription}/penalty'
      },
      export: {
        DETAILS: '/exports/excel'
      },
      live: {
        LIST: '/live/bookings',
        DETAILS: '/live/bookings/{booking}',
        GATES: '/live/parkingGateHistory'
      }
    }
  }
};
