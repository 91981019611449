import { Action, createReducer, on } from '@ngrx/store';
import {
  ParkingDetailState,
  ParkingDetailsFormData,
  ParkingLocation,
  ParkingSpace
} from './parking.details.models';
import {
  parkingLoadSucess,
  parkingLoadFailure,
  loadParking,
  loadFormData,
  loadFormDataSucess,
  loadFormDataFailure,
  loadParkingAccess,
  parkingAccessLoadSucess,
  parkingAccessLoadFailure,
  createParkingAccess,
  parkingAccessCreationSucess,
  parkingAccessCreationFailure,
  parkingCreate,
  parkingCreateSucess,
  parkingCreateFailure,
  parkingAccessDeletionFailure,
  parkingAccessDeletionSucess,
  deleteParkingAccess,
  partnerCreateSucess,
  partnerCreateFailure,
  partnerCreate,
  postParkingAccessPositions,
  parkingAccessPositionsSucess,
  parkingAccessPositionsFailure,
  loadParkingLocation,
  parkingLocationLoadSucess,
  parkingLocationLoadFailure,
  loadParkingCustomers,
  parkingCustomersLoadSucess,
  parkingCustomersLoadFailure,
  locationCreate,
  locationCreateSucess,
  locationCreateFailure,
  loadParkingPrices,
  parkingPricesLoadSucess,
  parkingPricesLoadFailure,
  openGate,
  openGateSucess,
  openGateFailure,
  updateParkingPrices,
  parkingPricesUpdateSucess,
  parkingPricesUpdateFailure,
  loadParkingQuotas,
  parkingQuotasLoadSucess,
  parkingQuotasLoadFailure,
  deleteParkingQuota,
  parkingQuotasDeleteSucess,
  parkingQuotasDeleteFailure,
  pushParkingQuota,
  parkingQuotasPushSucess,
  parkingQuotasPushFailure,
  loadParkingWaiting,
  parkingWaitingLoadFailure,
  parkingWaitingLoadSucess,
  loadParkingSpace,
  parkingSpaceLoadFailure,
  parkingSpaceLoadSucess,
  deleteParkingSpace,
  parkingSpacesDeleteSucess,
  parkingSpacesDeleteFailure,
  createParkingSpace,
  parkingSpaceCreationSucess,
  parkingSpaceCreationFailure,
  loadParkingQuotaImpacts,
  parkingQuotaImpactsLoadSucess,
  parkingQuotaImpactsLoadFailure,
  loadParkingMonthlyRates,
  parkingMonthlyRatesLoadSucess,
  parkingMonthlyRatesLoadFailure
} from './parking.details.actions';

import {
  createCommentInternalSucess,
  createCommentInternal,
  createCommentInternalFailure,
  createQuotaComment,
  createQuotaCommentFailure,
  createQuotaCommentSucess
} from '../parkings.actions';
import { ParkingAccess } from '../parkings.models';
import { PaginatedListResponse } from '../../../../../core/models/http-response.models';

export const initialState: ParkingDetailState = {
  access: {
    loading: false,
    error: null,
    list: {
      data: [],
      links: null,
      meta: null
    }
  },
  location: {
    loading: false,
    error: null,
    data: null
  },
  gates: null,
  loading: false,
  error: null,
  current: null,
  filters: {
    list: {
      partners: null,
      types: null,
      features: null,
      templates: null
    },
    loading: false,
    error: null
  },
  customers: {
    loading: false,
    error: null,
    list: {
      data: [],
      links: null,
      meta: null
    }
  },
  prices: {
    loading: false,
    error: null,
    data: null
  },
  monthlyRates: {
    loading: false,
    error: null,
    data: null
  },
  quotas: {
    loading: false,
    error: null,
    data: {
      quotas: null,
      holydays: null
    }
  },
  impacts: {
    loading: false,
    error: null,
    data: null
  },
  waiting: {
    loading: false,
    error: null,
    list: null
  },
  space: {
    loading: false,
    error: null,
    list: null
  }
};

const reducer = createReducer(
  initialState,
  on(createCommentInternalSucess, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: null,
      current: {
        ...state.current,
        ...{
          data: {
            ...state.current.data,
            comment_internal: action.result.data.content
          }
        }
      }
    }
  })),
  on(createCommentInternal, (state, action) => ({
    ...state,
    ...{
      loading: true,
      error: null
    }
  })),
  on(createCommentInternalFailure, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error
    }
  })),
  on(createQuotaCommentSucess, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: null,
      current: {
        ...state.current,
        ...{
          data: {
            ...state.current.data,
            quotas_comment: action.result.data.comment
          }
        }
      }
    }
  })),
  on(createQuotaComment, (state, action) => ({
    ...state,
    ...{
      loading: true,
      error: null
    }
  })),
  on(createQuotaCommentFailure, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error
    }
  })),
  on(parkingLoadSucess, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: null,
      current: action.result
    }
  })),
  on(parkingLoadFailure, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error,
      current: null
    }
  })),

  on(loadParkingLocation, (state, action) => ({
    ...state,
    ...{
      location: {
        loading: true,
        error: null,
        data: state.location.data
      }
    }
  })),

  on(parkingLocationLoadSucess, (state, action) => ({
    ...state,
    ...{
      location: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(parkingLocationLoadFailure, (state, action) => ({
    ...state,
    ...{
      location: {
        loading: false,
        error: action.error,
        data: initialState.location.data
      }
    }
  })),

  on(locationCreate, (state, action) => ({
    ...state,
    ...{
      location: {
        data: state.location.data,
        loading: true,
        error: null
      }
    }
  })),
  on(locationCreateSucess, (state, action) => ({
    ...state,
    ...{
      location: {
        loading: false,
        error: null,
        dataUpdate: action.result.data
      }
    }
  })),

  on(locationCreateFailure, (state, action) => {
    return {
      ...state,
      ...{
        location: {
          data: state.location.data,
          loading: false,
          error: action.error
        }
      }
    };
  }),

  on(loadFormData, (state, action) => ({
    ...state,
    ...{
      filters: {
        loading: true,
        error: null,
        list: state.filters.list
      }
    }
  })),

  on(loadFormDataSucess, (state, action) => ({
    ...state,
    ...{
      filters: {
        loading: false,
        error: null,
        list: action.result
      }
    }
  })),

  on(loadFormDataFailure, (state, action) => ({
    ...state,
    ...{
      filters: {
        loading: false,
        error: action.error,
        list: initialState.filters.list
      }
    }
  })),
  on(loadParking, (state, action) => ({
    ...state,
    ...{
      loading: true,
      id: action.id
    }
  })),
  on(loadParkingAccess, (state, action) => ({
    ...state,
    ...{
      access: {
        loading: true,
        list: initialState.access.list,
        error: null
      }
    }
  })),

  on(parkingAccessLoadSucess, (state, action) => ({
    ...state,
    ...{
      access: {
        loading: false,
        list: action.result,
        error: null
      }
    }
  })),
  on(parkingAccessLoadFailure, (state, action) => ({
    ...state,
    ...{
      access: {
        loading: false,
        list: initialState.access.list,
        error: action.error
      }
    }
  })),

  on(createParkingAccess, (state, action) => ({
    ...state,
    ...{
      access: {
        list: state.access.list,
        loading: true,
        error: null
      }
    }
  })),

  on(parkingAccessCreationSucess, (state, action) => {
    const stateData: PaginatedListResponse<ParkingAccess> = JSON.parse(
      JSON.stringify(state.access.list)
    );
    if (!action.isUpdate) {
      stateData.data.push(action.result.data);
    }
    return {
      ...state,
      ...{
        access: {
          list: stateData,
          loading: false,
          error: null
        }
      }
    };
  }),

  on(parkingAccessCreationFailure, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: false,
          error: action.error
        }
      }
    };
  }),

  on(openGate, (state, action) => ({
    ...state,
    ...{
      gates: state.gates,
      loading: true,
      error: null
    }
  })),

  on(openGateSucess, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        error: null,
        gates: action.result
      }
    };
  }),

  on(openGateFailure, (state, action) => {
    return {
      ...state,
      ...{
        loading: false,
        error: action.error,
        gates: null
      }
    };
  }),

  on(parkingCreate, (state, action) => ({
    ...state,
    ...{
      loading: true,
      error: null
    }
  })),

  on(parkingCreateSucess, (state, action) => {
    return {
      ...state,
      ...{
        current: action.result,
        loading: false,
        error: null
      }
    };
  }),

  on(parkingCreateFailure, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error
    }
  })),

  on(parkingAccessDeletionFailure, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: false,
          error: action.error
        }
      }
    };
  }),

  on(parkingAccessDeletionSucess, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: false,
          error: null
        }
      }
    };
  }),

  on(postParkingAccessPositions, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: true
        }
      }
    };
  }),

  on(parkingAccessPositionsSucess, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: false
        }
      }
    };
  }),

  on(parkingAccessPositionsFailure, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: false
        }
      }
    };
  }),

  on(deleteParkingAccess, (state, action) => {
    return {
      ...state,
      ...{
        access: {
          list: state.access.list,
          loading: true,
          error: null
        }
      }
    };
  }),

  on(partnerCreate, (state, action) => ({
    ...state,
    ...{
      loading: true,
      error: null
    }
  })),
  on(partnerCreateSucess, (state, action) => {
    const filters: ParkingDetailsFormData = JSON.parse(
      JSON.stringify(state.filters.list)
    );
    filters.partners.push({
      id: action.result.id,
      label: action.result.name
    });
    return {
      ...state,
      ...{
        loading: false,
        error: null,
        filters: {
          loading: false,
          error: null,
          ...{
            list: filters
          }
        }
      }
    };
  }),

  on(partnerCreateFailure, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error
    }
  })),

  on(loadParkingCustomers, (state, action) => ({
    ...state,
    ...{
      customers: {
        loading: true,
        error: null,
        list: initialState.customers.list
      }
    }
  })),
  on(parkingCustomersLoadSucess, (state, action) => ({
    ...state,
    ...{
      customers: {
        loading: false,
        error: null,
        list: action.result
      }
    }
  })),
  on(parkingCustomersLoadFailure, (state, action) => ({
    ...state,
    ...{
      customers: {
        loading: false,
        error: action.error,
        list: initialState.customers.list
      }
    }
  })),

  on(loadParkingMonthlyRates, (state, action) => ({
    ...state,
    ...{
      monthlyRates: {
        loading: true,
        error: null,
        data: initialState.monthlyRates.data
      }
    }
  })),

  on(parkingMonthlyRatesLoadSucess, (state, action) => ({
    ...state,
    ...{
      monthlyRates: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(parkingMonthlyRatesLoadFailure, (state, action) => ({
    ...state,
    ...{
      monthlyRates: {
        loading: false,
        error: action.error,
        data: initialState.monthlyRates.data
      }
    }
  })),

  on(loadParkingPrices, (state, action) => ({
    ...state,
    ...{
      prices: {
        loading: true,
        error: null,
        data: initialState.prices.data
      }
    }
  })),

  on(parkingPricesLoadSucess, (state, action) => ({
    ...state,
    ...{
      prices: {
        loading: false,
        error: null,
        data: action.result.data
      }
    }
  })),

  on(parkingPricesLoadFailure, (state, action) => ({
    ...state,
    ...{
      prices: {
        loading: false,
        error: action.error,
        data: initialState.prices.data
      }
    }
  })),

  on(updateParkingPrices, (state, action) => ({
    ...state,
    ...{
      prices: {
        loading: true,
        error: null,
        data: state.prices.data
      }
    }
  })),

  on(parkingPricesUpdateSucess, (state, action) => ({
    ...state,
    ...{
      current: {
        ...state.current,
        ...{
          data: {
            ...state.current.data,
            ...{
              has_bookings: action.result.data.has_bookings,
              has_subscriptions: action.result.data.has_subscriptions
            }
          }
        }
      },
      prices: {
        loading: false,
        error: null,
        data: {
          has_bookings: action.result.data.has_bookings,
          has_subscriptions: action.result.data.has_subscriptions,
          shop_price_booking_id: action.result.data.shop_price_booking_id,
          shopPriceSubscriptions: action.result.data.shop_price_subscriptions
        }
      }
    }
  })),

  on(parkingPricesUpdateFailure, (state, action) => ({
    ...state,
    ...{
      prices: {
        loading: false,
        error: action.error,
        data: state.prices.data
      }
    }
  })),

  on(loadParkingQuotas, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: true,
        error: null,
        data: initialState.quotas.data
      }
    }
  })),

  on(parkingQuotasLoadSucess, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(parkingQuotasLoadFailure, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: false,
        error: action.error,
        data: initialState.quotas.data
      }
    }
  })),

  on(deleteParkingQuota, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: true,
        error: null,
        data: state.quotas.data
      }
    }
  })),

  on(parkingQuotasDeleteSucess, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: false,
        error: null,
        data: state.quotas.data
      }
    }
  })),

  on(parkingQuotasDeleteFailure, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: false,
        error: action.error,
        data: state.quotas.data
      }
    }
  })),

  on(pushParkingQuota, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: true,
        error: null,
        data: state.quotas.data
      }
    }
  })),

  on(parkingQuotasPushSucess, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: false,
        error: null,
        data: state.quotas.data
      }
    }
  })),

  on(parkingQuotasPushFailure, (state, action) => ({
    ...state,
    ...{
      quotas: {
        loading: false,
        error: action.error,
        data: state.quotas.data
      }
    }
  })),

  on(loadParkingWaiting, (state, action) => ({
    ...state,
    ...{
      waiting: {
        loading: true,
        error: null,
        list: initialState.waiting.list
      }
    }
  })),

  on(parkingWaitingLoadFailure, (state, action) => ({
    ...state,
    ...{
      waiting: {
        loading: false,
        error: action.error,
        list: initialState.waiting.list
      }
    }
  })),

  on(parkingWaitingLoadSucess, (state, action) => ({
    ...state,
    ...{
      waiting: {
        loading: false,
        error: null,
        list: action.result
      }
    }
  })),

  on(loadParkingSpace, (state, action) => ({
    ...state,
    ...{
      space: {
        loading: true,
        error: null,
        list: initialState.space.list
      }
    }
  })),

  on(parkingSpaceLoadFailure, (state, action) => ({
    ...state,
    ...{
      space: {
        loading: false,
        error: action.error,
        list: initialState.space.list
      }
    }
  })),

  on(parkingSpaceLoadSucess, (state, action) => ({
    ...state,
    ...{
      space: {
        loading: false,
        error: null,
        list: action.result
      }
    }
  })),

  on(deleteParkingSpace, (state, action) => ({
    ...state,
    ...{
      space: {
        loading: true,
        error: null,
        list: state.space.list
      }
    }
  })),

  on(parkingSpacesDeleteSucess, (state, action) => ({
    ...state,
    ...{
      space: {
        loading: false,
        error: null,
        list: state.space.list
      }
    }
  })),

  on(parkingSpacesDeleteFailure, (state, action) => ({
    ...state,
    ...{
      space: {
        loading: false,
        error: action.error,
        list: state.space.list
      }
    }
  })),
  on(createParkingSpace, (state, action) => ({
    ...state,
    ...{
      space: {
        list: state.space.list,
        loading: true,
        error: null
      }
    }
  })),

  on(parkingSpaceCreationSucess, (state, action) => {
    const stateData: PaginatedListResponse<ParkingSpace> = JSON.parse(
      JSON.stringify(state.space.list)
    );
    if (!action.isUpdate) {
      stateData.data.push(action.result.data);
    }
    return {
      ...state,
      ...{
        space: {
          list: stateData,
          loading: false,
          error: null
        }
      }
    };
  }),

  on(parkingSpaceCreationFailure, (state, action) => {
    return {
      ...state,
      ...{
        space: {
          list: state.space.list,
          loading: false,
          error: action.error
        }
      }
    };
  }),

  on(loadParkingQuotaImpacts, (state, action) => ({
    ...state,
    ...{
      impacts: {
        loading: true,
        error: null,
        data: initialState.impacts.data
      }
    }
  })),

  on(parkingQuotaImpactsLoadSucess, (state, action) => ({
    ...state,
    ...{
      impacts: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(parkingQuotaImpactsLoadFailure, (state, action) => ({
    ...state,
    ...{
      impacts: {
        loading: false,
        error: null,
        data: initialState.impacts.data
      }
    }
  }))
);

export function parkingDetailReducer(
  state: ParkingDetailState | undefined,
  action: Action
): ParkingDetailState {
  return reducer(state, action);
}
