import { Store } from '@ngrx/store';
import { AppState } from '../../core.module';
import { selectAuth } from '../auth.selectors';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RolesGuardService {
  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const roles = route.data.roles as Array<string>;
    if (!!roles) {
      return this.hasAccesGranted(roles).pipe(
        tap(granted => {
          if (!granted) {
            this.showWarning();
          }
        })
      );
    }
    this.showWarning();
    return of(false);
  }

  private showWarning() {
    this.translate.get('lpa.global.access_forbidden').subscribe(warning => {
      this.notificationService.warn(warning);
    });
  }

  hasAccesGranted(roles: Array<string>): Observable<boolean> {
    return this.store.select(selectAuth).pipe(
      switchMap(auth => {
        if (!!auth.userInfo && !!auth.userInfo.user_role) {
          return of(roles.indexOf(auth.userInfo.user_role.label) === -1);
        }
        return of(false);
      })
    );
  }
}
