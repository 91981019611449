import { loadHourlyRates, hourlyRatesLoadSucess, hourlyRatesLoadFailure, deleteHourlyRates, hourlyRatesDeleteSucess, hourlyRatesDeleteFailure, loadHourlyRate, hourlyRateLoadFailure, hourlyRateLoadSucess, pushHourlyRate, hourlyRatePushSucess, loadHourlyRatePrices, hourlyRatePricesLoadSucess, hourlyRatePricesLoadFailure, hourlyRatePushFailure, pushHourlyRatePrices, hourlyRatePricesPushFailure, hourlyRatePricesPushSucess, syncHourlyRatePrices, loadHourlyRateParkings, hourlyRateParkingsLoadFailure, hourlyRateParkingsLoadSucess } from './hourly-rates.actions';
import { HourlyRatesState } from './hourly-rates.models';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: HourlyRatesState = {
    rates: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        }
    },
    detail: {
        loading: false,
        error: null,
        data: {
            data: null
        }
    },
    associatedRates : {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        }
    },
    associatedParkings : {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        }
    }
};
const reducer = createReducer(
    initialState,
    on(loadHourlyRates, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: true,
                error: null,
                list: initialState.rates.list
            }
        }
    })),

    on(hourlyRatesLoadSucess, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(hourlyRatesLoadFailure, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: false,
                error: action.error,
                list: initialState.rates.list
            }
        }
    })),

    on(deleteHourlyRates, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: true,
                error: null,
                list: state.rates.list
            }
        }
    })),

    on(hourlyRatesDeleteSucess, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: false,
                error: null,
                list: state.rates.list
            }
        }
    })),

    on(hourlyRatesDeleteFailure, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: false,
                error: action.error,
                list: state.rates.list
            }
        }
    })),

    on(loadHourlyRate, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                data: state.detail.data
            }
        }
    })),

    on(hourlyRateLoadSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                data: action.result
            }
        }
    })),

    on(hourlyRateLoadFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                data: initialState.detail.data
            }
        }
    })),


    on(pushHourlyRate, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                data: state.detail.data
            }
        }
    })),

    on(hourlyRatePushSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                data: {
                    data: action.result
                }
            }
        }
    })),

    on(hourlyRatePushFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                data: state.detail.data
            }
        }
    })),


    on(syncHourlyRatePrices, (state, action) => ({
        ...state,
        ...{
            associatedRates : {
                loading: true,
                error: null,
                list: initialState.associatedRates.list
            }
        }
    })),
    on(loadHourlyRatePrices, (state, action) => ({
        ...state,
        ...{
            associatedRates : {
                loading: true,
                error: null,
                list: initialState.associatedRates.list
            }
        }
    })),

    on(hourlyRatePricesLoadSucess, (state, action) => ({
        ...state,
        ...{
            associatedRates: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(hourlyRatePricesLoadFailure, (state, action) => ({
        ...state,
        ...{
            associatedRates: {
                loading: false,
                error: action.error,
                list: initialState.associatedRates.list
            }
        }
    })),


    on(loadHourlyRateParkings, (state, action) => ({
        ...state,
        ...{
            associatedParkings : {
                loading: true,
                error: null,
                list: initialState.associatedParkings.list
            }
        }
    })),
    on(hourlyRateParkingsLoadFailure, (state, action) => ({
        ...state,
        ...{
            associatedParkings : {
                loading: false,
                error: action.error,
                list: initialState.associatedParkings.list
            }
        }
    })),
    on(hourlyRateParkingsLoadSucess, (state, action) => ({
        ...state,
        ...{
            associatedParkings : {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),


    on(pushHourlyRatePrices, (state, action) => ({
        ...state,
        ...{
            associatedRates : {
                loading: true,
                error: null,
                list: state.associatedRates.list
            }
        }
    })),

    on(hourlyRatePricesPushSucess, (state, action) => ({
        ...state,
        ...{
            associatedRates: {
                loading: false,
                error: null,
                list: state.associatedRates.list
            }
        }
    })),

    on(hourlyRatePricesPushFailure, (state, action) => ({
        ...state,
        ...{
            associatedRates: {
                loading: false,
                error: action.error,
                list: state.associatedRates.list
            }
        }
    })),
)
export function hourlyRatesReducer(
    state: HourlyRatesState | undefined,
    action: Action
): HourlyRatesState {
    return reducer(state, action);
}
