import { DashboardState } from './../features/home/dashboard/core/dashboard.models';
import { MonthlyRatesState } from './../features/home/monthly-rates/core/monthly-rates.models';
import { HourlyRatesState } from './../features/home/hourly-rates/core/hourly-rates.models';
import { CustomersState } from './../features/home/customers/core/customers.models';
import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { environment } from '../../environments/environment';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { AuthState } from './auth/auth.model';
import { authReducer } from './auth/auth.reducer';
import { RouterStateUrl } from './router/router.state';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from './settings/settings.model';
import { ParkingsState } from '../features/home/parkings/core/parkings.models';
import { parkingsReducer } from '../features/home/parkings/core/parkings.reducers';
import { ParkingDetailState } from '../features/home/parkings/core/details/parking.details.models';
import { parkingDetailReducer } from '../features/home/parkings/core/details/parking.details.reducers';
import { VehiclesState } from '../features/home/vehicles/core/vehicles.models';
import { vehiclesReducer } from '../features/home/vehicles/core/vehicles.reducers';
import { UsersState } from '../features/home/users/core/users.models';
import { usersReducer } from '../features/home/users/core/users.reducers';
import { customersReducer } from '../features/home/customers/core/customers.reducers';
import { hourlyRatesReducer } from '../features/home/hourly-rates/core/hourly-rates.reducers';
import { monthlyRatesReducer } from '../features/home/monthly-rates/core/monthly-rates.reducers';
import { promoReducer } from '../features/home/promo-codes/core/promo-codes.reducers';
import { cmsReducer } from '../features/home/cms/core/cms.reducers';
import { exportReducer } from '../features/home/export/core/export.reducers';
import { PromoState } from '../features/home/promo-codes/core/promo-codes.models';
import { CmsState } from '../features/home/cms/core/cms.models';
import { ExportState } from '../features/home/export/core/export.models';
import { BookingsState } from '../features/home/bookings/core/bookings.models';
import { bookingsReducer } from '../features/home/bookings/core/bookings.reducers';
import { PaymentsState } from '../features/home/payments/core/payments.models';
import { paymentsReducer } from '../features/home/payments/core/payments.reducers';
import { SubscriptionsState } from '../features/home/subscriptions/core/subscriptions.models';
import { subscriptionsReducer } from '../features/home/subscriptions/core/subscriptions.reducers';
import { LivesState } from '../features/home/live/core/lives.models';
import { livesReducer } from '../features/home/live/core/lives.reducers';
import { dashboardReducer } from '../features/home/dashboard/core/dashboard.reducers';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  settings: settingsReducer,
  router: routerReducer,
  parkings: parkingsReducer,
  parkingDetails: parkingDetailReducer,
  hourlyRates: hourlyRatesReducer,
  monthlyRates: monthlyRatesReducer,
  vehicles: vehiclesReducer,
  users: usersReducer,
  customers: customersReducer,
  promo: promoReducer,
  cms: cmsReducer,
  export: exportReducer,
  bookings: bookingsReducer,
  payments: paymentsReducer,
  subscriptions: subscriptionsReducer,
  lives: livesReducer,
  dashboard: dashboardReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
  if (!environment.test) {
    metaReducers.unshift(debug);
  }
}

export const selectAuthState = createFeatureSelector<AppState, AuthState>(
  'auth'
);

export const selectParkingsState = createFeatureSelector<
  AppState,
  ParkingsState
>('parkings');

export const selectParkingDetailState = createFeatureSelector<
  AppState,
  ParkingDetailState
>('parkingDetails');

export const selectHourlyRatesState = createFeatureSelector<
  AppState,
  HourlyRatesState
>('hourlyRates');

export const selectMonthlyRatesState = createFeatureSelector<
  AppState,
  MonthlyRatesState
>('monthlyRates');

export const selectVehiclesState = createFeatureSelector<
  AppState,
  VehiclesState
>('vehicles');

export const selectUsersState = createFeatureSelector<AppState, UsersState>(
  'users'
);

export const selectCustomersState = createFeatureSelector<
  AppState,
  CustomersState
>('customers');

export const selectPromoState = createFeatureSelector<AppState, PromoState>(
  'promo'
);

export const selectCmsState = createFeatureSelector<AppState, CmsState>('cms');

export const selectExportState = createFeatureSelector<AppState, ExportState>(
  'export'
);

export const selectBookingsState = createFeatureSelector<
  AppState,
  BookingsState
>('bookings');
export const selectPaymentsState = createFeatureSelector<
  AppState,
  PaymentsState
>('payments');
export const selectSubscriptionsState = createFeatureSelector<
  AppState,
  SubscriptionsState
>('subscriptions');
export const selectLivesState = createFeatureSelector<AppState, LivesState>(
  'lives'
);
export const selectDashboardState = createFeatureSelector<
  AppState,
  DashboardState
>('dashboard');

export const selectSettingsState = createFeatureSelector<
  AppState,
  SettingsState
>('settings');

export const selectRouterState = createFeatureSelector<
  AppState,
  RouterReducerState<RouterStateUrl>
>('router');

export interface AppState {
  auth: AuthState;
  settings: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  parkings: ParkingsState;
  parkingDetails: ParkingDetailState;
  hourlyRates: HourlyRatesState;
  monthlyRates: MonthlyRatesState;
  vehicles: VehiclesState;
  users: UsersState;
  customers: CustomersState;
  promo: PromoState;
  cms: CmsState;
  export: ExportState;
  bookings: BookingsState;
  payments: PaymentsState;
  subscriptions: SubscriptionsState;
  lives: LivesState;
  dashboard: DashboardState;
}
