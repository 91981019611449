import { Action, createReducer, on } from '@ngrx/store';
import { SubscriptionsState } from './subscriptions.models';
import { SubscriptionsLoadFailure, SubscriptionsLoadSucess, loadSubscriptions, loadSubscriptionFilters, loadSubscriptionsFiltersSucess, loadSubscriptionFiltersFailure, loadSubscriptionDetail, subscriptionLoadDetailSucess, subscriptionLoadDetailFailure, creatSubscription, creatSubscriptionSucess, creatSubscriptionFailure, updateAccess, updateAccessSucess, updateAccessFailure, loadSubscriptionCancelDates, loadSubscriptionCancelDatesSucess, loadSubscriptionCancelDatesFailure } from './subscriptions.actions';

export const initialState: SubscriptionsState = {
    subscriptions: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        },
        filters: {
            loading: false,
            error: null,
            listFilter: {
                states: null
            }
        }
    },
    detail: {
        loading: false,
        error: null,
        list: {
            data: null,
        }
    },
    cancelDate: {
        loading: false,
        error: null,
        success: null,
        dates: null
    }


};
const reducer = createReducer(
    initialState,
    on(SubscriptionsLoadSucess, (state, action) => ({
        ...state,
        ...{
            subscriptions: {
                loading: false,
                error: null,
                list: action.result,
                filters: initialState.subscriptions.filters
            }
        }
    })),
    on(SubscriptionsLoadFailure, (state, action) => ({
        ...state,
        ...{
            subscriptions: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.subscriptions.filters
            }
        }
    })),
    on(loadSubscriptions, (state, action) => ({
        ...state,
        ...{
            subscriptions: {
                loading: true,
                error: null,
                list: initialState.subscriptions.list,
                filters: initialState.subscriptions.filters
            }
        }
    })),
    on(loadSubscriptionFilters, (state, action) => ({
        ...state,
        ...{
            subscriptions: {
                loading: true,
                error: null,
                list: initialState.subscriptions.list,
                filters: {
                    loading: true,
                    error: null,
                    listFilter: state.subscriptions.filters.listFilter
                }
            }
        }
    })),

    on(loadSubscriptionsFiltersSucess, (state, action) => ({
        ...state,
        ...{
            subscriptions: {
                loading: false,
                error: null,
                list: initialState.subscriptions.list,
                filters: {
                    loading: false,
                    error: null,
                    listFilter: action.result
                }
            }
        }
    })),

    on(loadSubscriptionFiltersFailure, (state, action) => ({
        ...state,
        ...{
            subscriptions: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.subscriptions.filters
            }
        }
    })),


    on(updateAccess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: state.detail.list
            }
        }
    })),

    on(updateAccessFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: state.detail.list
            }
        }
    })),

    on(updateAccessSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: state.detail.list
            }
        }
    })),


    on(loadSubscriptionDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(subscriptionLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(subscriptionLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),

    on(creatSubscription, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(creatSubscriptionSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: state.detail.list
            }
        }
    })),

    on(creatSubscriptionFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),

    on(loadSubscriptionCancelDates, (state, action) => ({
        ...state,
        ...{
            cancelDate: {
                loading: true,
                error: null,
                success: initialState.cancelDate.success,
                dates: initialState.cancelDate.dates,
            }
        }
    })),

    on(loadSubscriptionCancelDatesSucess, (state, action) => ({
        ...state,
        ...{
            cancelDate: {
                loading: false,
                error: null,
                success: action.result.success,
                dates: action.result.dates
            }
        }
    })),

    on(loadSubscriptionCancelDatesFailure, (state, action) => ({
        ...state,
        ...{
            cancelDate: {
                loading: false,
                error: action.error,
                success: initialState.cancelDate.success,
                dates: initialState.cancelDate.dates,
            }
        }
    })),

)
export function subscriptionsReducer(
    state: SubscriptionsState | undefined,
    action: Action
): SubscriptionsState {
    return reducer(state, action);
}