import { CustomersState } from './customers.models';
import { Action, createReducer, on } from '@ngrx/store';
import { loadCustomers, customersLoadSucess, customersLoadFailure, loadCustomerDetail, customersLoadDetailSucess, customersLoadDetailFailure, pushCustomerData, customerPushSucess, customerPushFailure, resetPassword, resetPasswordFailure, resetPasswordSucess } from './customers.actions';
import { openGateSucess, openGateEmpty } from '../../parkings/core/details/parking.details.actions';
export const initialState: CustomersState = {
    customers: {
        loading: false,
        error: null,
        list: {
            data: [],
            meta: null,
            links: null
        }
    },
    detail: {
        loading: false,
        error: null,
        customer: {
            data: null
        }
    }
};
const reducer = createReducer(
    initialState,
    on(loadCustomers, (state, action) => ({
        ...state,
        ...{
            customers: {
                loading: true,
                error: null,
                list: initialState.customers.list
            }
        }
    })),

    on(customersLoadSucess, (state, action) => ({
        ...state,
        ...{
            customers: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(customersLoadFailure, (state, action) => ({
        ...state,
        ...{
            customers: {
                loading: false,
                error: action.error,
                list: initialState.customers.list
            }
        }
    })),

    on(loadCustomerDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                customer: initialState.detail.customer
            }
        }
    })),

    on(customersLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                customer: action.result
            }
        }
    })),
   
    on(openGateEmpty, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                customer: initialState.detail.customer      
          }
        }
    })),

    on(customersLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                customer: initialState.detail.customer
            }
        }
    })),

    on(pushCustomerData, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                customer: state.detail.customer
            }
        }
    })),

    on(customerPushSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                customer: !!action.result && !!action.result.data ? action.result : state.detail.customer
            }
        }
    })),

    on(customerPushFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                customer: state.detail.customer
            }
        }
    })),

    on(resetPassword, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                customer: state.detail.customer
            }
        }
    })),

    on(resetPasswordFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                customer: state.detail.customer
            }
        }
    })),

    on(resetPasswordSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                customer: state.detail.customer
            }
        }
    })),
)
export function customersReducer(
    state: CustomersState | undefined,
    action: Action
): CustomersState {
    return reducer(state, action);
}
