import { AuthState } from './auth.model';
import {
  authLogin,
  authLoginFailure,
  authLoginSuccess,
  authLogout,
  authRefreshTokenSuccess
} from './auth.actions';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: AuthState = {
  loading: false,
  token: null,
  userInfo: null,
  isAuthenticated: false,
  error: null,
};

const reducer = createReducer(
  initialState,
  on(authLogin, (state, action) => ({
    ...state,
    ...{
      isAuthenticated: false,
      loading: true,
      userInfo: null,
      token: null,
      error: null
    }
  })),
  on(authLoginSuccess, (state, action) => ({
    ...state,
    ...{
      isAuthenticated: true,
      loading: false,
      userInfo: action.userInfos,
      token: action.authToken,
      error: null
    }
  })),
  
  on(authLoginFailure, (state, action) => ({
    ...state,
    ...{
      isAuthenticated: false,
      loading: false,
      userInfo: null,
      token: null,
      error: action.error
    }
  })),
  on(authRefreshTokenSuccess, (state, action) => ({
    ...state,
    ...{ token: action.authToken }
  })),
  on(authLogout, state => ({
    ...state,
    ...{
      isAuthenticated: false,
      loading: false,
      userInfo: null,
      token: null,
      error: null
    }
  }))
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
