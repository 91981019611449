import { HourlyRate } from './../../hourly-rates/core/hourly-rates.models';
import { Subscription } from 'rxjs';
import {
  PaginatedListResponse,
  DetailsItem
} from '../../../../core/models/http-response.models';
import { MonthlyRate } from '../../monthly-rates/core/monthly-rates.models';

export interface Parking {
  id?: number;
  dematerialized?: boolean;
  name?: string;
  description?: string;
  contact?: string;
  parking_address?: ParkingAddress;
  parking_address_pedestrian?: ParkingAddress;
  information_localisation?: string;
  information_pedestrian?: string;
  created_at?: string;
  reference?: string;
  reference_opendata?: string;
  height_max?: string;
  width_max?: string;
  length_max?: string;
  comment_internal?: string;
  quotas_comment?: string;
  user_partner_id?: string;
  residence_name?: string;
  status?: string;
  contract_start?: string;
  contract_end?: string;
  contract_type?: string;
  parking_features?: any[];
  vehicle_templates?: any[];
  has_subscriptions?: boolean;
  has_bookings?: boolean;
  shop_price_subscriptions?: MonthlyRate[];
  shop_price_booking_id?: number;
}

export interface ParkingAccess {
  id?: number;
  label_internal: string;
  label: string;
  code?: string;
  token: string;
  access_type: string;
  way_type: string;
  status: string;
  thekeys_id?: string;
  connected: boolean;
  gateway_id: string;
}

export interface ParkingAddress {
  id?: number;
  address?: string;
  address_2?: string;
  postal?: string;
  city?: string;
  lat?: string;
  lon?: string;
}

export interface FilterItem {
  id: string;
  label: string;
}

export interface ParkingFilter {
  name: string;
  data: FilterItem[];
}

export interface ParkingFilters {
  cities: ParkingFilter;
  partners: ParkingFilter;
  types: ParkingFilter;
  statutes: ParkingFilter;
}

export interface SelectedFilters {
  cities: FilterItem;
  partners: FilterItem;
  types: FilterItem;
  states: FilterItem;
}

export interface ParkingFiltersState {
  list: ParkingFilters;
  loading: boolean;
  error: any;
}

export interface ParkingsState {
  loading: boolean;
  error?: any;
  list: PaginatedListResponse<Parking>;
  filters: ParkingFiltersState;
}

export const parkingStatusLabels = {
  PREPARATION: 'lpa.parking.status.preparation',
  PUBLISHED: 'lpa.parking.status.published',
  NOT_PUBLISHED: 'lpa.parking.status.unpublished'
};
export const parkingStatusTooltipLabel = {
  PREPARATION: 'lpa.parking.status.tooltip.preparation',
  PUBLISHED: 'lpa.parking.status.tooltip.published',
  NOT_PUBLISHED: 'lpa.parking.status.tooltip.unpublished'
};
