import { Vehicle } from './../../features/home/vehicles/core/vehicles.models';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'lpa-vehicle-link',
  templateUrl: './vehicle-link.component.html',
  styleUrls: ['./vehicle-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleLinkComponent {

  @Input()
  vehicle: Vehicle;
  @Input()
  disabledLink = false;

  constructor() { }


}
