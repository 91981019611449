import { Action, createReducer, on } from '@ngrx/store';
import { UsersState } from './users.models';
import { UsersLoadFailure, UsersLoadSucess, loadUsers, loadUserFilters, loadUsersFiltersSucess, loadUserFiltersFailure, loadUserDetail, userLoadDetailSucess, userLoadDetailFailure, creatUser, creatUserSucess, creatUserFailure } from './users.actions';

export const initialState: UsersState = {
    users: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        },
        filters: {
            loading: false,
            error: null,
            listFilter: {
                partners: null,
                roles: null,
            }
        }
    },
    detail: {
        loading: false,
        error: null,
        list: {
            data: null,
        }
    }

};
const reducer = createReducer(
    initialState,
    on(UsersLoadSucess, (state, action) => ({
        ...state,
        ...{
            users: {
                loading: false,
                error: null,
                list: action.result,
                filters: initialState.users.filters
            }
        }
    })),
    on(UsersLoadFailure, (state, action) => ({
        ...state,
        ...{
            users: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.users.filters
            }
        }
    })),
    on(loadUsers, (state, action) => ({
        ...state,
        ...{
            users: {
                loading: true,
                error: null,
                list: initialState.users.list,
                filters: initialState.users.filters
            }
        }
    })),
    on(loadUserFilters, (state, action) => ({
        ...state,
        ...{
            users: {
                loading: true,
                error: null,
                list: initialState.users.list,
                filters: {
                    loading: true,
                    error: null,
                    listFilter: state.users.filters.listFilter
                }
            }
        }
    })),

    on(loadUsersFiltersSucess, (state, action) => ({
        ...state,
        ...{
            users: {
                loading: false,
                error: null,
                list: initialState.users.list,
                filters: {
                    loading: false,
                    error: null,
                    listFilter: action.result
                }
            }
        }
    })),

    on(loadUserFiltersFailure, (state, action) => ({
        ...state,
        ...{
            users: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },
                filters: initialState.users.filters
            }
        }
    })),


    on(loadUserDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(userLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(userLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),
    on(creatUser, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),
    on(creatUserSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),
    on(creatUserFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    }))
)
export function usersReducer(
    state: UsersState | undefined,
    action: Action
): UsersState {
    return reducer(state, action);
}