import { NotificationService } from './../notifications/notification.service';
import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse && err.status !== 401) {
            if (!!err.error && !!err.error.errors) {
              let strError = '';

              Object.values(err.error.errors).forEach((value) => {
                if (Array.isArray(value) && value.length > 0) {
                  strError += `- ${value[0]} \n`
                }
              });

              if (!!strError) {
                console.log(strError);
                this.notificationService.warn(strError)
                return;
              }

            }

          }
          if (err.status !== 401) {
            this.translate.get('lpa.global.unexpected_error').subscribe(errorTxt => {
              this.notificationService.error(errorTxt);
            })
          }
        }
      })
    );
  }
}
