import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import {
  tap,
  withLatestFrom,
  catchError,
  switchMap,
  map
} from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';

import {
  authLogin,
  authLogout,
  authLoginSuccess,
  authLoginFailure,
} from './auth.actions';
import { AuthService } from './auth.service';
import { initialState } from './auth.reducer';
import { select, Store } from '@ngrx/store';
import { State } from './auth.model';
import { selectAuth } from './auth.selectors';
import { of } from 'rxjs';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) { }

  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogin),
        switchMap(action => {
          return this.authService.retrieveAuthToken(action.loginRequest).pipe(
            switchMap((authToken) => {
              return this.authService.retrieveUserData(authToken).pipe(
                map(infos => {
                  return { token: authToken, userInfos: infos };
                })
              )
            }),
            switchMap((result) => {
              return [
                authLoginSuccess({ authToken: result.token, userInfos: result.userInfos })
              ];
            }),
            catchError(err => of(authLoginFailure({ error: err })))
          );
        })
      ),
    { dispatch: true }
  );


  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginSuccess),
        withLatestFrom(this.store.pipe(select(selectAuth))),
        tap(([a, authState]) => {
          this.localStorageService.setItem(AUTH_KEY, {
            ...authState
          });
        })
      ),
    { dispatch: false }
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.router.navigate(['']);
          this.localStorageService.setItem(AUTH_KEY, initialState);
        })
      ),
    { dispatch: false }
  );

}
