import { Action, createReducer, on } from '@ngrx/store';
import { DashboardState } from './dashboard.models';
import { statisticsDetail, statisticsDetailSucess, statisticsDetailFailure, loadGraph, loadGraphSucess, loadGraphFailure } from './dashboard.actions';

export const initialState: DashboardState = {
    statistics: {
        loading: false,
        error: null,
        dashboard: null
    },
    graph: {
        loading: false,
        error: null,
        data: null
    }
};
const reducer = createReducer(
    initialState,

    on(statisticsDetail, (state, action) => ({
        ...state,
        ...{
            statistics: {
                loading: true,
                error: null,
                dashboard: initialState.statistics.dashboard
            }
        }
    })),
    on(statisticsDetailSucess, (state, action) => ({
        ...state,
        ...{
            statistics: {

                loading: false,
                error: null,
                dashboard: action.result
            }

        }
    })),
    on(statisticsDetailFailure, (state, action) => ({
        ...state,
        ...{
            statistics: {
                loading: false,
                error: action.error,
                dashboard: initialState.statistics.dashboard
            }
        }
    })),

    on(loadGraph, (state, action) => ({
        ...state,
        ...{
            graph: {
                loading: true,
                error: null,
                data: initialState.graph.data
            }
        }
    })),
    on(loadGraphSucess, (state, action) => ({
        ...state,
        ...{
            graph: {
                loading: false,
                error: null,
                data: action.result
            }
        }
    })),
    on(loadGraphFailure, (state, action) => ({
        ...state,
        ...{
            graph: {
                loading: false,
                error: action.error,
                data: initialState.graph.data
            }
        }
    })),
)
export function dashboardReducer(
    state: DashboardState | undefined,
    action: Action
): DashboardState {
    return reducer(state, action);
}