import { Lives, SelectedFilters, Gates, SelectedFiltersGates, GateOpening } from './lives.models';
import { createAction, props } from '@ngrx/store';
import { PaginatedListResponse } from '../../../../core/models/http-response.models';

export const loadLives = createAction('[Lives] Load data', props<{ page: number, q: string, filters: SelectedFilters, sortBy: string, sortOrder: string }>());
export const LivesLoadSucess = createAction('[Lives] Load data success', props<{ result: PaginatedListResponse<Lives> }>());
export const LivesLoadFailure = createAction('[Lives] Load data failure', props<{ error: any }>());


export const loadLiveDetail = createAction('[Lives] Load Detail data', props<{ id: string }>());
export const flushLiveDetail = createAction('[Lives] Flush Detail data');
export const liveLoadDetailSucess = createAction('[Lives] Load Detail data success', props<{ result: PaginatedListResponse<GateOpening> , id: string}>());
export const liveLoadDetailFailure = createAction('[Lives] Load Detail data failure', props<{ error: any }>());

export const loadLivesGates = createAction('[LivesGates] Load data', props<{ page: number, q: string, filters: SelectedFiltersGates, sortBy: string, sortOrder: string }>());
export const LivesGatesLoadSucess = createAction('[LivesGates] Load data success', props<{ result: PaginatedListResponse<Gates> }>());
export const LivesGatesLoadFailure = createAction('[LivesGates] Load data failure', props<{ error: any }>());