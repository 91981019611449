import { loadMonthlyRates, monthlyRatesLoadFailure, monthlyRatesLoadSucess, loadMonthlyRate, monthlyRateLoadSucess, monthlyRateLoadFailure, pushMonthlyRate, monthlyRatePushSucess, monthlyRatePushFailure, loadMonthlyRateParkings, monthlyRateParkingsLoadFailure, monthlyRateParkingsLoadSucess, verifyRate, verifyRateSucess, verifyRateFailure } from './monthly-rates.actions';
import { MonthlyRatesState } from './monthly-rates.models';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: MonthlyRatesState = {
    rates: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        }
    },
    detail: {
        loading: false,
        error: null,
        data: {
            data: null
        }
    },
    associatedParkings : {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        }
    },
    verify: {
        loading: false,
        error: null,
        verifyData: {
            success: null,
            hasSubscriptions: null,
            data: null
        }
    }
};
const reducer = createReducer(
    initialState,
    on(loadMonthlyRates, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: true,
                error: null,
                list: initialState.rates.list
            }
        }
    })),

    on(monthlyRatesLoadSucess, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(monthlyRatesLoadFailure, (state, action) => ({
        ...state,
        ...{
            rates: {
                loading: false,
                error: action.error,
                list: initialState.rates.list
            }
        }
    })),


    on(loadMonthlyRateParkings, (state, action) => ({
        ...state,
        ...{
            associatedParkings : {
                loading: true,
                error: null,
                list: initialState.associatedParkings.list
            }
        }
    })),
    on(monthlyRateParkingsLoadFailure, (state, action) => ({
        ...state,
        ...{
            associatedParkings : {
                loading: false,
                error: action.error,
                list: initialState.associatedParkings.list
            }
        }
    })),
    on(monthlyRateParkingsLoadSucess, (state, action) => ({
        ...state,
        ...{
            associatedParkings : {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),




    on(loadMonthlyRate, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                data: initialState.detail.data
            }
        }
    })),

    on(monthlyRateLoadSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                data: action.result
            }
        }
    })),

    on(monthlyRateLoadFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                data: initialState.detail.data
            }
        }
    })),

    on(pushMonthlyRate, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                data: state.detail.data
            }
        }
    })),
    on(monthlyRatePushSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                data: action.result
            }
        }
    })),
    on(monthlyRatePushFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                data: state.detail.data
            }
        }
    })),
    on(verifyRate, (state, action) => ({
        ...state,
        ...{
            verify: {
                loading: true,
                error: null,
                verifyData: state.verify.verifyData
            }
        }
    })),

    on(verifyRateSucess, (state, action) => ({
        ...state,
        ...{
            verify: {
                loading: false,
                error: null,
                verifyData: action.result
            }
        }
    })),

    on(verifyRateFailure, (state, action) => ({
        ...state,
        ...{
            verify: {
                loading: false,
                error: action.error,
                verifyData: state.verify.verifyData
            }
        }
    })),

)
export function monthlyRatesReducer(
    state: MonthlyRatesState | undefined,
    action: Action
): MonthlyRatesState {
    return reducer(state, action);
}
