import { Action, createReducer, on } from '@ngrx/store';
import { PromoState } from './promo-codes.models';
import { PromoLoadFailure, PromoLoadSucess, loadPromo, loadPromoDetail, promoLoadDetailSucess, promoLoadDetailFailure, creatPromo, creatPromoSucess, creatPromoFailure } from './promo-codes.actions';

export const initialState: PromoState = {
    promo: {
        loading: false,
        error: null,
        list: {
            data: [],
            links: null,
            meta: null
        },

    },
    detail: {
        loading: false,
        error: null,
        list: {
            data: null,
        }
    }

};
const reducer = createReducer(
    initialState,
    on(PromoLoadSucess, (state, action) => ({
        ...state,
        ...{
            promo: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),
    on(PromoLoadFailure, (state, action) => ({
        ...state,
        ...{
            promo: {
                loading: false,
                error: action.error,
                list: {
                    data: [],
                    links: null,
                    meta: null,
                },

            }
        }
    })),
    on(loadPromo, (state, action) => ({
        ...state,
        ...{
            promo: {
                loading: true,
                error: null,
                list: initialState.promo.list,
            }
        }
    })),


    on(loadPromoDetail, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: true,
                error: null,
                list: initialState.detail.list
            }
        }
    })),

    on(promoLoadDetailSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),

    on(promoLoadDetailFailure, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: action.error,
                list: initialState.detail.list
            }
        }
    })),
    on(creatPromo, (state, action) => ({
        ...state,
        ...{
            loading: true,
            error: null
        }
    })),
    on(creatPromoSucess, (state, action) => ({
        ...state,
        ...{
            detail: {
                loading: false,
                error: null,
                list: action.result
            }
        }
    })),
    on(creatPromoFailure, (state, action) => ({
        ...state,
        ...{
            loading: false,
            error: action.error
        }
    }))
)
export function promoReducer(
    state: PromoState | undefined,
    action: Action
): PromoState {
    return reducer(state, action);
}