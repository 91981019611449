import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectIsAuthenticated } from './auth.selectors';
import { AppState } from '../core.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  isAuthenticated = false;

  constructor(private store: Store<AppState>, private router: Router) {
    this.store.select(selectIsAuthenticated).subscribe(auth => {
      this.isAuthenticated = auth;
    });
  }

  canActivate(): boolean {
    if (!this.isAuthenticated) {
      this.router.navigate(['login']);
    }
    return this.isAuthenticated;
  }
}

export const APP_ROLES = {
  admin: 'admin',
  manager: 'manager',
  exploitation: 'exploitation',
  remote_manager: 'remoteManager',
  partner: 'partner'
};

export const APP_ROLES_FORBIDDEN_ACCESS = {
  DASHBOARD: {
    read: [],
    write: []
  },
  EXPORT: {
    read: [APP_ROLES.remote_manager],
    write: [APP_ROLES.remote_manager]
  },
  LIVE: {
    read: [APP_ROLES.partner],
    write: [
      APP_ROLES.manager,
      APP_ROLES.exploitation,
      APP_ROLES.remote_manager,
      APP_ROLES.partner
    ]
  },
  PARKINGS: {
    read: [],
    write: [APP_ROLES.exploitation, APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  PARKING_CUSTOMER_NAME: {
    read: [APP_ROLES.partner],
    write: []
  },
  INTERNAL_COMMENT: {
    read: [APP_ROLES.partner],
    write: [
      APP_ROLES.exploitation,
      APP_ROLES.remote_manager,
      APP_ROLES.partner,
      APP_ROLES.manager
    ]
  },
  PARKING_TARIF: {
    read: [APP_ROLES.partner, APP_ROLES.remote_manager],
    write: [APP_ROLES.exploitation, APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  PARKING_QUOTA: {
    read: [],
    write: [APP_ROLES.exploitation, APP_ROLES.remote_manager]
  },
  PARKING_QUOTA_EDIT: {
    read: [],
    write: [APP_ROLES.partner]
  },
  HOURLY_RATES: {
    read: [APP_ROLES.remote_manager, APP_ROLES.partner],
    write: [APP_ROLES.exploitation, APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  MONTHLY_RATES: {
    read: [APP_ROLES.remote_manager, APP_ROLES.partner],
    write: [APP_ROLES.exploitation, APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  PROMO_CODES: {
    read: [APP_ROLES.remote_manager, APP_ROLES.partner],
    write: [
      APP_ROLES.exploitation,
      APP_ROLES.remote_manager,
      APP_ROLES.partner,
      APP_ROLES.manager
    ]
  },
  CLIENTS: {
    read: [APP_ROLES.partner],
    write: [APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  VEHICLES: {
    read: [APP_ROLES.partner],
    write: [APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  BOOKINGS: {
    read: [APP_ROLES.partner],
    write: [APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  SUBSCRIPTIONS: {
    read: [APP_ROLES.partner],
    write: [APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  PAYMENTS: {
    read: [APP_ROLES.partner],
    write: [APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  DISCOUNTS: {
    read: [APP_ROLES.partner, APP_ROLES.remote_manager],
    write: [APP_ROLES.exploitation, APP_ROLES.remote_manager, APP_ROLES.partner]
  },
  EDITORIAL: {
    read: [APP_ROLES.partner, APP_ROLES.remote_manager],
    write: [
      APP_ROLES.manager,
      APP_ROLES.exploitation,
      APP_ROLES.remote_manager,
      APP_ROLES.partner
    ]
  },
  USERS: {
    read: [APP_ROLES.partner, APP_ROLES.remote_manager],
    write: [
      APP_ROLES.manager,
      APP_ROLES.exploitation,
      APP_ROLES.remote_manager,
      APP_ROLES.partner
    ]
  },
  OPEN_BARRER: {
    read: [],
    write: [APP_ROLES.partner]
  }
};
